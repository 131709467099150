import React, { useState, useEffect, useContext } from "react";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API, BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import { Card, Col, Row, Spinner } from "react-bootstrap";
import Navbar from "./Navbar";
import Footer from "./footer";
const Author = () => {
  const productURLMapping = {
    Anlook: "https://anlook.com/blog",
    BillPunch: "https://billpunch.com/blog",
  };
  const { setTransactionLoaderState } = useContext(GlobalContext);
  const [Blogdata, setBlogdata] = useState([]);
  const [authorData, setAuthorData] = useState(null);
  const GetBlogsByAuthors = async (authorSlug) => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({ author_slug: authorSlug }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetBlogsByAuthors`,
        requestOptions
      );
      const data = await res.json();
      console.log("GetBlogsByAuthors response:", data);

      if (data.status === "0") {
        const authorInfo = data.data[0];
        setAuthorData({
          name: authorInfo.author_name,
          image: authorInfo.author_image,
        });
        const filteredPosts = authorInfo.posts.filter(
          (post) => post.product_name === "BillPunch"
        );
        setBlogdata(filteredPosts);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("Error fetching blogs:", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
 
  useEffect(() => {
    const author = localStorage.getItem("author");
    console.log("SessionStorage Author (raw):", author);

    if (author) {
      const parsedAuthor = JSON.parse(author);
      console.log("Author from sessionStorage (parsed):", parsedAuthor);
      setAuthorData(parsedAuthor);
      GetBlogsByAuthors(parsedAuthor.author_slug);
    } else {
      console.warn("No author found in sessionStorage.");
    }
  }, []);
  const renderPlaceholders = (count) => {
    return Array.from({ length: count }).map((_, index) => (
      <Col key={`placeholder-${index}`} className="p-2">
        <Card className="h-100 p-0" style={{ visibility: "hidden" }}>
          <Card.Body />
        </Card>
      </Col>
    ));
  };
  return (
    <>
      <Navbar />

      <div className="row">
        <div className="col-md-1"></div>
        <div className="col-md-10">
          <div>
            <div className="row justify-content-center">
              <div className="col-6">
                <div className="card p-3" style={{background:"#F4F6FF", border:".4rem solid #E4E0E1"}}>
                  {authorData ? (
                    <>
                      {" "}
                      <div className="d-flex justify-content-center">
                        <img
                          src={authorData.image}
                          alt={authorData.name}
                          style={{
                            width: "150px",
                            height: "150px",
                            borderRadius: "50%",
                          }}
                        />
                      </div>
                      <h5 className="d-flex mt-2 justify-content-center">
                        {authorData.name}
                      </h5>
                    </>
                  ) : (
                    <p>Loading author data...</p>
                  )}
                </div>
              </div>
            </div>
            {Blogdata.length > 0 ? (
  <Row className="d-flex flex-wrap justify-content-start">
    {Blogdata.map((post) => (
      <div
        key={post.post_id}
        style={{
          flex: '0 0 20%', 
          maxWidth: '20%', 
          padding: '0.5rem', 
        }}
      >
        <Card className="h-100 p-0">
          <Card.Img
            variant="top"
            src={post.post_featured_image}
            alt={post.post_featured_image_alt}
            style={{
              width: "100%",
              // height: "200px",
              objectFit: "cover",
            }}
          />
          <Card.Body className="p-2">
            <p
              className="mb-1"
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 2,
                color: "#000",
                fontSize: "1rem",
              }}
            >
              <b>{post.post_title}</b>
            </p>
            <Card.Text
              style={{
                display: "-webkit-box",
                WebkitBoxOrient: "vertical",
                overflow: "hidden",
                WebkitLineClamp: 3,
              }}
            >
              {post.post_excerpt}
            </Card.Text>
            <Card.Link
              target="_blank"
              href={
                productURLMapping[post.product_name]
                  ? `${productURLMapping[post.product_name]}/${post.post_slug}`
                  : `/blog/${post.post_slug}`
              }
            >
              Read More
            </Card.Link>
          </Card.Body>
        </Card>
      </div>
    ))}
  </Row>
) : (
  <p>No blogs found for this author.</p>
)}

          </div>
        </div>
        <div className="col-md-1"></div>
      </div>
      <Footer />
    </>
  );
};

export default Author;
