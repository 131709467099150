import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const Projectmanagment = () => {
  const [pageData, setPageData] = useState(null);

  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "12");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };

  useEffect(() => {
    fetchSeoData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.src = "./on_seo.js";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar />
      <h1 className="text-center mt-4 px-5 mx-5">
        Best Project Management Software in India 2025 – Plan, Execute & Deliver
        Projects Seamlessly
      </h1>
      <div className="d-flex justify-content-center">
        <img
            src="https://billpunch.com/billpunchImages/Peak%20Productivity.png"
            style={{ height: "50vh" }}
          /></div>
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
  <h2>Effortless Project Planning & Execution</h2>
  <p>
    Managing multiple projects efficiently is crucial for business success.
    BillPunch’s Project Management Software provides an end-to-end solution to
    plan, execute, and track projects while ensuring timely delivery and resource
    optimization. From task assignments to budget tracking, our software is ideal
    for IT firms, construction companies, digital agencies, and enterprises.
  </p>

  <h5>Key Features of BillPunch's Project Management System</h5>

  <h5>Project Planning & Scheduling</h5>
  <ul>
    <li>Define project goals, milestones, and deadlines.</li>
    <li>Use Gantt charts and timeline views for clear project roadmaps.</li>
  </ul>

  <h5>Team Collaboration & File Sharing</h5>
  <ul>
    <li>Real-time collaboration tools for seamless teamwork.</li>
    <li>Attach and manage documents within project tasks.</li>
  </ul>

  <h5>Task Automation & Resource Management</h5>
  <ul>
    <li>Auto-assign tasks based on workload and availability.</li>
    <li>Track team productivity with real-time progress updates.</li>
  </ul>

  <h5>Budgeting & Expense Tracking</h5>
  <ul>
    <li>Monitor project expenses and prevent cost overruns.</li>
    <li>Automate invoicing and billing for client projects.</li>
  </ul>

  <h5>Performance Analytics & Reports</h5>
  <ul>
    <li>Track project KPIs with customizable reports.</li>
    <li>Gain insights into efficiency and cost-effectiveness.</li>
  </ul>
</div>

        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Projectmanagment;
