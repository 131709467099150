import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const Workspacemanagment = () => {
  const [pageData, setPageData] = useState(null);

  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "12");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };

  useEffect(() => {
    fetchSeoData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.src = "./on_seo.js";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar />
      <h1 className="text-center mt-3 px-5 mx-5">
        Best Workspace Management Software in India 2025 – Optimize Office &
        Remote Workspaces
      </h1>
      <div className="d-flex justify-content-center">
        <img
            src="https://billpunch.com/billpunchImages/Workday%20a%20Breeze.png"
            style={{ height: "50vh" }}
          /></div>
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
  <h2>Maximize Office Productivity with Smart Workspace Management</h2>
  <p>
    BillPunch’s Workspace Management System is designed for businesses looking
    to optimize office space, manage hybrid work environments, and improve
    employee collaboration. Whether your team works on-site, remotely, or in a
    hybrid model, our AI-driven workspace management software helps you
    maximize efficiency and reduce operational costs.
    <br/><a href="https://www.youtube.com/watch?v=TtJ7D2yxtAY" target="_blank">
  https://www.youtube.com/watch?v=TtJ7D2yxtAY
</a>
  </p>

  <h5>Key Features of BillPunch's Workspace Management Software</h5>

  <h5>Smart Desk & Meeting Room Booking</h5>
  <ul>
    <li>AI-powered seat allocation for hybrid work models.</li>
    <li>Real-time booking for desks, meeting rooms, and co-working spaces.</li>
  </ul>

  <h5>Employee Access & Attendance Tracking</h5>
  <ul>
    <li>Geolocation-based check-ins for remote workers.</li>
    <li>Biometric and RFID-based access control integration.</li>
  </ul>

  <h5>Workspace Utilization Analytics</h5>
  <ul>
    <li>Monitor real-time workspace occupancy and trends.</li>
    <li>Optimize office layouts to improve productivity and reduce costs.</li>
  </ul>

  <h5>Seamless Integration with HR & Payroll Systems</h5>
  <ul>
    <li>Sync employee schedules with HRMS and attendance systems.</li>
    <li>Automate workspace expense management and budgeting.</li>
  </ul>

  <h5>Security & Compliance</h5>
  <ul>
    <li>Role-based access control for restricted areas.</li>
    <li>Data encryption to protect employee and company information.</li>
  </ul>

  <h5>Why Choose BillPunch for Workspace Management?</h5>
  <ul>
    <li>Improve workspace utilization and cut down on unnecessary costs.</li>
    <li>Enable hybrid work models with smart office tools.</li>
    <li>Enhance security with access control and monitoring.</li>
    <li>Boost productivity by creating a seamless working environment.</li>
  </ul>
</div>

        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Workspacemanagment;
