import React, { useContext, useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Form, Button } from "react-bootstrap";
import Footer from "./footer";
import Navbar from "./Navbar";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import swal from "sweetalert";
import { Helmet } from "react-helmet";
const Billpunchcontact = () => {

  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    mobile: "",
    organizationName: "",
    organizationType: "",
    organizationAge: "",
    organizationGST: "",
    address: "",
    telephone: "",
    teamSize: "",
  });
  const [errors, setErrors] = useState({});
  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = async () => {
    setTransactionLoaderState(true);

    try {
      var myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        name: formData.name,
        email: formData.email,
        mobile: formData.mobile,
        firm_name: formData.organizationName,
        firm_type: formData.organizationType,
        firm_age: formData.organizationAge,
        firm_gst: formData.organizationGST,
        firm_location: formData.address,
        firm_mobile: formData.telephone,
        firm_size: formData.teamSize,
      });
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/SuperadminContactus`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        swal("Success", data.result, "success");
      } else {
        swal("Error", data.result, "warning");
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
    setTransactionLoaderState(false);
  };
  const validateForm = (e) => {
    e.preventDefault();
    let valid = true;
    const errors = {};
  
    if (!formData.name.trim()) {
      errors.name = "Name is required";
      valid = false;
    } else if (formData.name.trim() !== formData.name) {
      errors.name = "Name should not start with a space";
      valid = false;
    }
  
    if (!formData.email.trim()) {
      errors.email = "Email is required";
      valid = false;
    } else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      errors.email = "Email address is invalid";
      valid = false;
    }
  
    if (!formData.mobile.trim()) {
      errors.mobile = "Mobile is required";
      valid = false;
    } else if (!/^\d{10}$/.test(formData.mobile)) {
      errors.mobile = "Mobile number should be 10 digits";
      valid = false;
    }
  
    if (!formData.organizationName.trim()) {
      errors.organizationName = "Organization name is required";
      valid = false;
    } else if (formData.organizationName.trim() !== formData.organizationName) {
      errors.organizationName = "Organization name should not start with a space";
      valid = false;
    }
  
    if (!formData.organizationType.trim()) {
      errors.organizationType = "Organization type is required";
      valid = false;
    } else if (formData.organizationType.trim() !== formData.organizationType) {
      errors.organizationType = "Organization type should not start with a space";
      valid = false;
    }
  
    if (!formData.organizationAge.trim()) {
      errors.organizationAge = "Organization age is required";
      valid = false;
    } else if (formData.organizationAge.trim() !== formData.organizationAge) {
      errors.organizationAge = "Organization age should not start with a space";
      valid = false;
    }
  
    if (!formData.organizationGST.trim()) {
      errors.organizationGST = "Organization GST is required";
      valid = false;
    } else if (formData.organizationGST.trim() !== formData.organizationGST) {
      errors.organizationGST = "Organization GST should not start with a space";
      valid = false;
    }
  
    if (!formData.address.trim()) {
      errors.address = "Address is required";
      valid = false;
    } else if (formData.address.trim() !== formData.address) {
      errors.address = "Address should not start with a space";
      valid = false;
    }
  
    if (!formData.telephone.trim()) {
      errors.telephone = "Telephone is required";
      valid = false;
    } else if (formData.telephone.trim() !== formData.telephone) {
      errors.telephone = "Telephone should not start with a space";
      valid = false;
    }
  
    if (!formData.teamSize.trim()) {
      errors.teamSize = "Team size is required";
      valid = false;
    } else if (formData.teamSize.trim() !== formData.teamSize) {
      errors.teamSize = "Team size should not start with a space";
      valid = false;
    }
  
    setErrors(errors);
    if (Object.keys(errors).length === 0 && valid) {
      handleSubmit();
    }
  };
  const [pageData, setPageData] = useState(null);
  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();
      
      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];
  
        console.log("Pages Data:", pages); 
  
        const faqPageData = pages.find(page => page.page_id === "2");
  
        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData(); 
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.src = "./on_seo.js";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
          

      <Navbar />
      <h1 className="mt-3 d-flex justify-content-center">
        Talk to Us
      </h1>
      <div className="row">
        <div className="col-md-1"></div>
        <div className="col">
          {" "}
          <div className="row">
            <div className="col-md-6 col-12 mt-3">
              <div className=" ">
                {" "}
                <Form onSubmit={validateForm}>
                  <div className="row m-0 p-0">
                    <Form.Group className="col" controlId="name">
                    <Form.Label>Name</Form.Label>
                      <Form.Control
                        type="text"
                        name="name"
                        placeholder="Name"
                        value={formData.name}
                        onChange={handleChange}
                        isInvalid={errors.name}
                      />
                      <Form.Control.Feedback
                        style={{ fontSize: "1rem",fontFamily: "Arial, sans-serif" }}
                        type="invalid"
                      >
                        {errors.name}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col" controlId="email">
                    <Form.Label>Email</Form.Label>
                      <Form.Control
                        type="email"
                        name="email"
                        placeholder="Email"
                        value={formData.email}
                        onChange={handleChange}
                        isInvalid={errors.email}
                      />
                      <Form.Control.Feedback
                        style={{ fontSize: "1rem",fontFamily: "Arial, sans-serif" }}
                        type="invalid"
                      >
                        {errors.email}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="row m-0 p-0">
                  <Form.Group className="col" controlId="organizationName">
                  <Form.Label>Organization</Form.Label>
                    <Form.Control
                      type="text"
                      name="organizationName"
                      placeholder="Organization Name"
                      value={formData.organizationName}
                      onChange={handleChange}
                      isInvalid={errors.organizationName}
                    />
                    <Form.Control.Feedback
                      style={{ fontSize: "1rem",fontFamily: "Arial, sans-serif" }}
                      type="invalid"
                    >
                      {errors.organizationName}
                    </Form.Control.Feedback>
                  </Form.Group>
                  </div>
                  <div className="row m-0 p-0">
                    <Form.Group className="col" controlId="mobile">
                    <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        type="tel"
                        name="mobile"
                        placeholder="Mobile"
                        value={formData.mobile}
                        onChange={handleChange}
                        isInvalid={errors.mobile}
                      />
                      <Form.Control.Feedback
                        style={{ fontSize: "1rem",fontFamily: "Arial, sans-serif" }}
                        type="invalid"
                      >
                        {errors.mobile}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col" controlId="organizationType">
                    <Form.Label>Type</Form.Label>
                      <Form.Control
                        type="text"
                        name="organizationType"
                        placeholder="Organization Type"
                        value={formData.organizationType}
                        onChange={handleChange}
                        isInvalid={errors.organizationType}
                      />
                      <Form.Control.Feedback
                        style={{ fontSize: "1rem" ,fontFamily: "Arial, sans-serif"}}
                        type="invalid"
                      >
                        {errors.organizationType}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <div className="row m-0 p-0">
                    <Form.Group className="col" controlId="organizationAge">
                    <Form.Label>Established year</Form.Label>
                      <Form.Control
                        type="text"
                        name="organizationAge"
                        placeholder="Established year"
                        value={formData.organizationAge}
                        onChange={handleChange}
                        isInvalid={errors.organizationAge}
                      />
                      <Form.Control.Feedback
                        style={{ fontSize: "1rem" ,fontFamily: "Arial, sans-serif"}}
                        type="invalid"
                      >
                        {errors.organizationAge}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col" controlId="organizationGST">
                    <Form.Label>GST</Form.Label>
                      <Form.Control
                        type="text"
                        name="organizationGST"
                        placeholder="Organization GST"
                        value={formData.organizationGST}
                        onChange={handleChange}
                        isInvalid={errors.organizationGST}
                      />
                      <Form.Control.Feedback
                        style={{ fontSize: "1rem",fontFamily: "Arial, sans-serif" }}
                        type="invalid"
                      >
                        {errors.organizationGST}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="row m-0 p-0">
                    <Form.Group className="col" controlId="telephone">
                    <Form.Label>Telephone</Form.Label>
                      <Form.Control
                        type="tel"
                        name="telephone"
                        placeholder="Telephone"
                        value={formData.telephone}
                        onChange={handleChange}
                        isInvalid={errors.telephone}
                      />
                      <Form.Control.Feedback
                        style={{ fontSize: "1rem",fontFamily: "Arial, sans-serif" }}
                        type="invalid"
                      >
                        {errors.telephone}
                      </Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="col" controlId="teamSize">
                    <Form.Label>Team Size</Form.Label>
                      <Form.Control
                        type="text"
                        name="teamSize"
                        placeholder="Team Size"
                        value={formData.teamSize}
                        onChange={handleChange}
                        isInvalid={errors.teamSize}
                      />
                      <Form.Control.Feedback
                        style={{ fontSize: "1rem",fontFamily: "Arial, sans-serif" }}
                        type="invalid"
                      >
                        {errors.teamSize}
                      </Form.Control.Feedback>
                    </Form.Group>
                  </div>
                  <div className="row m-0 p-0">
                  <Form.Group className="col" controlId="address">
                  <Form.Label>Address</Form.Label>
                    <Form.Control
                      type="text"
                      name="address"
                      placeholder="Address"
                      value={formData.address}
                      onChange={handleChange}
                      isInvalid={errors.address}
                    />
                    <Form.Control.Feedback
                      style={{ fontSize: "1rem",fontFamily: "Arial, sans-serif" }}
                      type="invalid"
                    >
                      {errors.address}
                    </Form.Control.Feedback>
                  </Form.Group>
                  </div>
                  <div className="text-center">
                    <Button type="submit" variant="primary">
                      Submit
                    </Button>
                  </div>
                </Form>
              </div>
            </div>
            <div className="col-md-6 col-12 mt-2">
              <MapContainer />
            </div>
          </div>{" "}
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Billpunchcontact;

const MapContainer = () => {
  const latitude = 28.6166;
  const longitude = 77.381;

  const mapUrl = `https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3507.7459672014744!2d${longitude}!3d${latitude}!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390ce4d3aaf1509f%3A0xf7dc800f7a9b57dd!2sITHUM!5e0!3m2!1sen!2sin!4v1672244704601!5m2!1sen!2sin`;

  return (
    <div className="mt-3">
      <div>
        <iframe
          src={mapUrl}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          style={{ width: "100%", height: "60vh", border: "0" }}
        ></iframe>
      </div>
    </div>
  );
};
