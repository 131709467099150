import React, { useEffect, useRef, useState, useCallback } from "react";
import "./Landing.css";
import { BASE_URL_API_TRANXT_UAT_API, BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";

function MyLanding() {
  const [cards, setCards] = useState([]);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const containerRef = useRef(null);

  const Getproductblogs = useCallback(async () => {
    if (isLoading) return;
    setIsLoading(true);
    try {
      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetProductBlogs`,
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({ product_name: "billpunch" }),
        }
      );
      const data = await response.json();
      if (data.status === "0") {
        setCards((prevCards) => [...prevCards, ...(data.data || [])]);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.error("Error:", error);
    } finally {
      setIsLoading(false);
    }
  }, [isLoading]);

  useEffect(() => {
    Getproductblogs();
  }, []);

  const updateCarousel = useCallback(() => {
    if (containerRef.current && cards.length > 0) {
      const cardWidth =
        containerRef.current.querySelector(".My-Landing-card").offsetWidth;
      const newTranslateX = -(currentIndex * cardWidth);
      containerRef.current.style.transform = `translateX(${newTranslateX}px)`;
      if (currentIndex >= cards.length - 5 && !isLoading) {
        Getproductblogs();
      }
    }
  }, [currentIndex, cards.length, isLoading, Getproductblogs]);

  const handlePrevClick = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 1, 0));
  };

  const handleNextClick = () => {
    setCurrentIndex((prevIndex) => prevIndex + 1);
  };

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth <= 768);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    updateCarousel();
  }, [currentIndex, isMobile, cards, updateCarousel]);

  return (
    <section>
      <div
        className={`My-Landing-card-carousel ${isMobile ? "mobile-view" : ""}`}
      >
        <div className="My-Landing-carousel-controls">
          <button
            className="My-Landing-carousel-btn My-Landing-prev"
            onClick={handlePrevClick}
          >
            &lt;
          </button>
          <button
            className="My-Landing-carousel-btn My-Landing-next"
            onClick={handleNextClick}
          >
            &gt;
          </button>
        </div>
        <div className="My-Landing-card-container" ref={containerRef}>
          {cards.map((item, i) => (
            <div
              key={i}
              className="My-Landing-card"
              style={{
                backgroundColor: "#fff",
                display: "flex",
                flexDirection: "column",
                boxShadow: "0 4px 8px 0 rgba(0,0,0,0.2)",
                height: "100%",
                transition: "opacity 0.5s ease-in-out",
              }}
            >
              <img
                src={item.post_featured_image}
                alt={item.post_title}
                style={{
                  maxWidth: "100%",
                  marginBottom: "1rem",
                  height: "40vh",
                  objectFit: "contain",    height: "auto", 
                }}
              />
              <div
                className="card-content"
                style={{
                  padding: "0 10px",
                  height: "15vvh",
                  overflow: "hidden",
                }}
              >
                <div className="row p-0 m-0">
                  <div className="col-2 p-0">
                  <FaUserCircle style={{color:"green", fontSize:"2rem"}} />

                  </div>
                  <div className="col d-flex align-items-center p-0">
                    <p
                      className="mx-2 mb-1"
                      style={{
                        textAlign: "start",
                        color: "#6d6d6d",
                        fontFamily: "Roboto, sans-serif",
                        fontSize: ".9rem",
                        fontWeight: "600",
                      }}
                    >
                      {item.post_author}
                    </p>
                  </div>
                </div>

                <div style={{ height: "8vh" }}>
                  <h6
                    className="mt-2"
                    style={{
                      textAlign: "start",
                      color: "#3E3232",
                      display: "-webkit-box",
                      WebkitBoxOrient: "vertical",
                      WebkitLineClamp: 2,
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                    }}
                  >
                    {item.post_title}
                  </h6>
                </div>

                <p
                  style={{
                    textAlign: "start",
                    color: "#3E3232",
                    display: "-webkit-box",
                    WebkitBoxOrient: "vertical",
                    WebkitLineClamp: 4,
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                  }}
                >
                  {item.post_excerpt}
                </p>
                <div className="row m-0 p-0">
                  <p
                    className="col m-0 p-0"
                    style={{
                      textAlign: "start",
                      color: "#6d6d6d",
                      fontFamily: "Roboto, sans-serif",
                      fontSize: ".9rem",
                      fontWeight: "600",
                    }}
                  >
                    {new Date(item.created_at).toLocaleDateString()}
                  </p>
                  <div>
                    <Link
                      to={`/blog/${item.post_slug}`}
                      onClick={() => {
                        console.log("click hua");
                        sessionStorage.setItem("Slug", JSON.stringify(item));
                      }}
                    >
                      Read More
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      {isLoading && <div>Loading more cards...</div>}
    </section>
  );
}

export default MyLanding;
