import React, { useContext, useState, useEffect } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import { Helmet } from "react-helmet";
const Plans = () => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [plans, setPlans] = useState([]);

  const getPlans = async () => {
    setTransactionLoaderState(true);
    try {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firmid: 1,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetSubscriptionPlans`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data.length > 0) {
        setPlans(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    getPlans();

    // ✅ Injecting the script directly into the DOM
    // const script = document.createElement("script");
    // script.innerHTML = `
    //   document.addEventListener("DOMContentLoaded", function() {
    //       console.log("Running SEO script...");

    //       const seoUrl = "https://seobix.com/integrations/meta_seo.php";
    //       const requestBody = JSON.stringify({
    //           weburl: "https://billpunch.com",
    //           fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc"
    //       });

    //       fetch(seoUrl, {
    //           method: "POST",
    //           headers: {
    //               "Content-Type": "application/json"
    //           },
    //           body: requestBody
    //       })
    //       .then(response => response.json())
    //       .then(seoData => {
    //           console.log("SEO Data:", seoData);

    //           if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
    //               console.error("Invalid SEO response format");
    //               return;
    //           }

    //           const currentUrl = window.location.href;
    //           const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
    //           if (!page) {
    //               console.error("Page not found in SEO data");
    //               return;
    //           }

    //           const { basic_settings, og_settings, keyword_settings } = page;

    //           if (basic_settings.tittle) document.title = basic_settings.tittle;

    //           const metaTags = {
    //               "description": basic_settings.meta_desc,
    //               "keywords": keyword_settings.map(k => k.keyword).join(", "),
    //               "og:title": og_settings.og_title,
    //               "og:description": og_settings.og_desc,
    //               "og:image": og_settings.og_image,
    //               "og:url": og_settings.og_url,
    //               "og:type": og_settings.og_type,
    //               "og:site_name": og_settings.og_sitename,
    //               "twitter:title": og_settings.twitter_title,
    //               "twitter:description": og_settings.twitter_desc,
    //               "twitter:image": og_settings.twitter_image
    //           };

    //           Object.entries(metaTags).forEach(([key, value]) => {
    //               if (value) {
    //                   let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
    //                   if (!metaTag) {
    //                       metaTag = document.createElement("meta");
    //                       if (key.startsWith("og:") || key.startsWith("twitter:")) {
    //                           metaTag.setAttribute("property", key);
    //                       } else {
    //                           metaTag.setAttribute("name", key);
    //                       }
    //                       document.head.appendChild(metaTag);
    //                   }
    //                   metaTag.setAttribute("content", value);
    //               }
    //           });
    //       })
    //       .catch(error => console.error("SEO Script Error:", error));
    //   });
    // `;
    // document.body.appendChild(script);

    // return () => {
    //   document.body.removeChild(script);
    // };
  }, []);


  return (
    <>
        
      <div>
        <div className="section-title">
          <h1
            className="mt-5 d-flex justify-content-center "
            style={{  color: "black", fontWeight: "500" }}
          >
            Pricing & Plans
          </h1>
          <div className="divider"></div>
        </div>
        <div className="row px-md-0 px-md-4 d-flex justify-content-center">
          {plans.map((plan) => (
            <div
              className={`col-12 col-md-3 mt-2 ${
                plan.id === "2" ? "plan-large" : ""
              }`}
              key={plan.id}
            >
              {plan.id === "1" && (
                <div
                  className="hover-cardplan mx-md-0 mx-5 m-0"
                  style={{
                    border: "2px solid #5BBCFF",
                    borderRadius: ".5rem",
                    backgroundColor: "rgba(0, 133, 255, 0.1)",
                  }}
                >
                  <div className="card-body">
                    <h5 className="text-center">{plan.plan_name}</h5>
                    <h6
                      className="card-subtitle mb-2 text-center"
                      style={{ color: "#1679AB" }}
                    >
                      ₹{plan.price[0].amount}/month
                    </h6>
                    <div className="d-flex justify-content-center mb-3">
                      <span
                        className="badge"
                        style={{ backgroundColor: "#5BBCFF", color: "white" }}
                      >
                        Best Value
                      </span>
                    </div>
                    <p
                      className="m-0 p-0 d-flex justify-content-center text-center"
                      style={{ fontSize: ".8rem", color: "#4D869C" }}
                    >
                      <b>{plan.plan_desc}</b>
                    </p>
                    <ul className="list-unstyled mt-3 mb-4">
                      {plan.features.map((feature) => (
                        <li key={feature.pk_feature_id}>
                          <b>{feature.feature_name}</b>: {feature.feature_desc}
                        </li>
                      ))}
                    </ul>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        style={{ backgroundColor: "#5BBCFF", color: "white" }}
                      >
                        Choose Plan
                      </button>
                    </div>
                  </div>
                </div>
              )}
              {plan.id === "2" && (
                <div
                  className="hover-cardplan mx-md-0 mx-5 m-0"
                  style={{
                    border: "2px solid #FFD700",
                    borderRadius: ".5rem",
                    backgroundColor: "#FFF7E6",
                    padding: "1.5rem",
                    
                  }}
                >
                  <div className="card-body m-0 p-0">
                    <h5 className="text-center">{plan.plan_name}</h5>
                    <h6
                      className="card-subtitle mb-2 text-center"
                      style={{ color: "#1679AB" }}
                    >
                      ₹{plan.price[0].amount}/month
                    </h6>
                    <div className="d-flex justify-content-center mb-3">
                      <span className="badge bg-warning text-dark">
                        Best Value
                      </span>
                    </div>
                    <ul className="list-unstyled mt-3 mb-4">
                      {plan.features.map((feature) => (
                        <li key={feature.pk_feature_id}>
                          <b>{feature.feature_name}</b>: {feature.feature_desc}
                        </li>
                      ))}
                    </ul>
                    <div className="d-flex justify-content-center mb-1">
                      <button
                        className="btn"
                        style={{ backgroundColor: "#FFA500", color: "white" }}
                      >
                        Choose Plan
                      </button>
                    </div>
                    <div className="">
                      <p className="m-0 p-0" style={{ fontSize: ".8rem", textAlign:"center" }}>
                        Additional are charged ₹100/employee/month.
                      </p>
                    </div>
                  </div>
                </div>
              )}
              {plan.id === "3" && (
                <div
                  className={`offer offer-success offer-radius hover-cardplan mx-md-0 mx-5 m-0`}
                  style={{ borderWidth: "2px" }}
                >
                  <div className="shape">
                    <div className="shape-text">New</div>
                  </div>
                  <div className="card-body">
                    <h5 className="text-center">{plan.plan_name}</h5>
                    <h6
                      className="card-subtitle mb-2 text-center"
                      style={{ color: "#1679AB" }}
                    >
                      ₹{plan.price[0].amount}/month
                    </h6>
                    <div className="d-flex justify-content-center mb-3">
                      <span
                        className="badge"
                        style={{ backgroundColor: "#135D66", color: "white" }}
                      >
                        Best Value
                      </span>
                    </div>
                    <p
                      className="m-0 p-0 d-flex justify-content-center text-center"
                      style={{ fontSize: ".8rem", color: "#0A6847" }}
                    >
                      <b>{plan.plan_desc}</b>
                    </p>
                    <ul className="list-unstyled mt-2 mb-4">
                      {plan.features.map((feature) => (
                        <li key={feature.pk_feature_id}>
                          <b>{feature.feature_name}</b>: {feature.feature_desc}
                        </li>
                      ))}
                    </ul>
                    <div className="d-flex justify-content-center">
                      <button
                        className="btn"
                        style={{ backgroundColor: "#135D66", color: "white" }}
                      >
                        Choose Plan
                      </button>
                    </div>
                  </div>
                </div>
              )}
            </div>
          ))}
        </div>
      </div>
    </>
  );
};

export default Plans;
