import React, { useState, useEffect, useContext } from "react";
import { Button } from "react-bootstrap";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import swal from "sweetalert";
import { Link } from "react-router-dom";
import Select from "react-select";
import { billpunchbasicauth } from "../../utils/Credentials";

const Payments = () => {
  const { password, username } = billpunchbasicauth;
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const [clickedRows, setClickedRows] = useState([]);
  const [selectedServiceType, setSelectedServiceType] = useState({});
  const [generatedsalary, setGeneratedSalary] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState("");
  const [selectedRows, setSelectedRows] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const processPayment = async (row) => {
    setTransactionLoaderState(true);
    const {
      id,
      total_amount,
      bankacc_no,
      ifsc_code,
      bank_name,
      ref_code,
      ref_name,
      date_new,
    } = row;

    if (clickedRows.includes(id)) {
      swal("Error", "Button already clicked for this row", "error");
      return;
    }
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          id: id,
          firmid: user.firm_id,
          purpose: "Miscellaneous",
          userid: "0",
          amount: total_amount,
          tpin: "1",
          serviceType: selectedServiceType[id] || "IMPS",
          accNo: bankacc_no,
          ifsc: ifsc_code,
          bankName: bank_name,
          mobile: ref_code,
          custName: ref_name,
          month: date_new,
        }),
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/SalaryManagement/SalaryPayroll`,

        requestOptions
      );

      const data = await response.json();

      if (data.status === "0") {
        swal("Success", data.msg, "success");
        getEmployeegeneratedList();
      } else {
        swal("Error", data.msg, "error");
        getEmployeegeneratedList();
      }
      setTransactionLoaderState(false);
      setClickedRows((prevClickedRows) => [...prevClickedRows, id]);
    } catch (error) {
      console.error("API call failed", error);
      swal("API call failed", "An error occurred", "error");
    }  setTransactionLoaderState(true);
  };

  const handleProcessAll = async () => {
    for (const row of selectedRows) {
      await processPayment(row);
    }
  };

  const getEmployeegeneratedList = async () => {
    try {
      setTransactionLoaderState(true);
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const raw = JSON.stringify({});

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetNewData`,
        requestOptions
      );
      const responseData = await res.json();

      if (responseData.status === "0") {
        setGeneratedSalary(responseData.data);
      } else {
        setGeneratedSalary([]);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };

  const handleSelectRow = (e, row) => {
    if (e.target.checked) {
      setSelectedRows([...selectedRows, row]);
    } else {
      setSelectedRows(selectedRows.filter((r) => r.id !== row.id));
    }

    setSelectAll(generatedsalary.length === selectedRows.length + 1);
  };

  const handleSelectAllRows = (e) => {
    if (e.target.checked) {
      setSelectedRows([...generatedsalary]);
      setSelectAll(true);
    } else {
      setSelectedRows([]);
      setSelectAll(false);
    }
  };

  useEffect(() => {
    getEmployeegeneratedList();
  }, [selectedMonth]);

  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  return (
    <div className="m-2">
      <div className="row">
        <h5 className="col px-3 pb-2" style={{ color: "#4267B2" }}>
          Process Payment
        </h5>
        <div className="col d-flex mr-2 justify-content-end">
          <div className="px-2">
            {/* <Link to="/retailer/Payout_History1">
              <Button
                className="btn-sm"
                style={{
                  cursor: "pointer",
                  border: "1px solid",
                  backgroundColor: "rgba(43, 193, 85, 0.1)",
                  color: "rgba(43, 193, 85, 1)",
                  bordercolor: "rgba(43, 193, 85, 1)",
                  borderRadius: ".4rem",
                }}
              >
                Payout History
              </Button>
            </Link> */}
          </div>

          <Link to="/retailer/Payrollprocesssalary">
            <Button className="btn-sm">Back</Button>
          </Link>
        </div>
      </div>

      <div className="m-3 d-flex justify-content-end">
        <Button
          onClick={handleProcessAll}
          className="btn-sm"
          style={{
            marginTop: "10px",
            backgroundColor: selectedRows.length > 0 ? "blue" : "gray",
            color: "white",
            borderColor: "blue",
          }}
          disabled={selectedRows.length === 0}
        >
          Process Selected
        </Button>
      </div>
      <hr className="m-0 p-0" />
      <div style={{ width: "100%" }}>
        <div style={{ alignItems: "center" }}>
          <div style={{ flex: "1" }}></div>
        </div>
      </div>
      <div className="scroll-inner admin-fix-width borderless-table">
        <table
          className="my-table mx-2 transparent-table text-center border-bottom"
          style={{
            width: "100%",
            overflowX: "auto",
            border: "0px",
            overflow: "auto",
          }}
        >
          <thead>
            <tr
              className="borderless-table text-center border-bottom"
              style={{ background: "white", color: "black" }}
            >
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                <input
                  type="checkbox"
                  checked={selectAll}
                  onChange={handleSelectAllRows}
                />
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Ref Id
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Ref Name
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Pan
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Bank
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                TDS
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Invoice No.
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Amount
              </th>
              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Aadhar pan link
              </th>

              <th
                className="text-center"
                style={{ background: "white", color: "black" }}
              >
                Pay
              </th>
            </tr>
          </thead>
          <tbody>
            {generatedsalary.length > 0 &&
              generatedsalary.map((data, index) => (
                <tr
                  className="table-salary border-bottom text-center"
                  key={data.id}
                >
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    <input
                      type="checkbox"
                      checked={selectedRows.some((row) => row.id === data.id)}
                      onChange={(e) => handleSelectRow(e, data)}
                    />
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    {data.ref_code}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    {data.ref_name}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    {data.pan_no}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    {data.bank_name}
                    <br />
                    {data.ifsc_code}
                    <br />
                    {data.bankacc_no}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    {data.tds}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    {data.invoice_no}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    {data.total_amount}
                  </td>
                  <td
                    className="text-center align-middle"
                    style={{ border: "none", fontSize: ".8rem", width: "9rem" }}
                  >
                    {data.aadhar_pan_link}
                  </td>

                  <td>
                    {data.status === "Y" ? (
                      <Button
                        className="btn-sm"
                        style={{
                          backgroundColor: "gray",
                          color: "white",
                          borderColor: "gray",
                        }}
                        disabled
                      >
                        Processed
                      </Button>
                    ) : (
                      <Button
                        onClick={() => processPayment(data)}
                        className="btn-sm"
                        style={{
                          backgroundColor: clickedRows.includes(data.id)
                            ? "green"
                            : "blue",
                          color: "white",
                          borderColor: "blue",
                        }}
                        disabled={clickedRows.includes(data.id)}
                      >
                        Process
                      </Button>
                    )}
                  </td>
                </tr>
              ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default Payments;
