import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const Taskmanagment = () => {
  const [pageData, setPageData] = useState(null);

  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "12");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };

  useEffect(() => {
    fetchSeoData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.innerHTML = `
      document.addEventListener("DOMContentLoaded", function() {
          console.log("Running SEO script...");

          const seoUrl = "https://seobix.com/integrations/meta_seo.php";
           const requestBody = JSON.stringify({
              weburl: "https://billpunch.com",
              fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc",
              type:"Seo"
          });

          fetch(seoUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: requestBody
          })
          .then(response => response.json())
          .then(seoData => {
              console.log("SEO Data:", seoData);

              if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
                  console.error("Invalid SEO response format");
                  return;
              }

             const currentUrl = window.location.href.replace(/\/$/, '').replace(/^https?:\/\/www\./, 'https://');
            console.log(currentUrl);
            const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
            if (!page) {
                console.error(currentUrl+"Page not found");
                return;
            }

              const { basic_settings, og_settings, keyword_settings } = page;

              if (basic_settings.tittle) document.title = basic_settings.tittle;

              const metaTags = {
                  "description": basic_settings.meta_desc,
                  "keywords": keyword_settings.map(k => k.keyword).join(", "),
                  "og:title": og_settings.og_title,
                  "og:description": og_settings.og_desc,
                  "og:image": og_settings.og_image,
                  "og:url": og_settings.og_url,
                  "og:type": og_settings.og_type,
                  "og:site_name": og_settings.og_sitename,
                  "twitter:title": og_settings.twitter_title,
                  "twitter:description": og_settings.twitter_desc,
                  "twitter:image": og_settings.twitter_image
              };

              Object.entries(metaTags).forEach(([key, value]) => {
                  if (value) {
                      let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
                      if (!metaTag) {
                          metaTag = document.createElement("meta");
                          if (key.startsWith("og:") || key.startsWith("twitter:")) {
                              metaTag.setAttribute("property", key);
                          } else {
                              metaTag.setAttribute("name", key);
                          }
                          document.head.appendChild(metaTag);
                      }
                      metaTag.setAttribute("content", value);
                  }
              });
          })
          .catch(error => console.error("SEO Script Error:", error));
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar />
      <h1 className="text-center mt-4 px-5 mx-5">
        Best Task Management Software in India 2025 – Organize & Track Workflows
        Efficiently
      </h1>
      <div className="d-flex justify-content-center">
        <img
            src="https://billpunch.com/billpunchImages/Workday%20a%20Breeze.png"
            style={{ height: "50vh" }}
          /></div>
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
  <h2>Simplify Task Planning & Enhance Productivity</h2>
  <p>
    Managing tasks effectively is essential for boosting productivity and meeting
    deadlines. BillPunch’s Task Management Software provides a centralized,
    cloud-based solution to assign, track, and manage tasks in real-time. Whether
    you're handling daily operations, team collaborations, or client projects, our
    automated task tracking system ensures seamless execution and accountability.
  </p>

  <h5>Key Features of BillPunch's Task Management Software</h5>

  <h5>Smart Task Creation & Assignment</h5>
  <ul>
    <li>Create, assign, and prioritize tasks with deadlines and dependencies.</li>
    <li>Drag-and-drop interface for easy task organization.</li>
  </ul>

  <h5>Real-Time Collaboration & Notifications</h5>
  <ul>
    <li>Team members receive instant task updates and reminders.</li>
    <li>In-app comments and file sharing for seamless collaboration.</li>
  </ul>

  <h5>Automated Workflow Management</h5>
  <ul>
    <li>Set task dependencies and automate recurring tasks.</li>
    <li>Kanban boards, Gantt charts, and calendar views for task tracking.</li>
  </ul>

  <h5>Time Tracking & Productivity Insights</h5>
  <ul>
    <li>Monitor time spent on tasks to enhance efficiency.</li>
    <li>Generate detailed reports on individual and team performance.</li>
  </ul>

  <h5>Mobile Accessibility & Cloud Sync</h5>
  <ul>
    <li>Access tasks from any device with mobile app support.</li>
    <li>Cloud-based task synchronization for remote teams.</li>
  </ul>

  <h5>Why Choose BillPunch for Task Management?</h5>
  <ul>
    <li>Boost team productivity with smart task automation.</li>
    <li>Improve collaboration through real-time task tracking.</li>
    <li>Enhance efficiency with detailed performance analytics.</li>
    <li>Reduce workload stress by automating repetitive tasks.</li>
  </ul>
</div>

        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Taskmanagment;
