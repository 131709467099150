import React, { useContext, useEffect, useState } from "react";
import { format, addDays, isSunday } from "date-fns";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import Select from "react-select";
import swal from "sweetalert";
import { BulkAttendanceAdmin } from "./Modal";
import { billpunchbasicauth } from "../../utils/Credentials";
import { FaHome } from "react-icons/fa";
import { Button, Col, Form, Row, Modal } from "react-bootstrap";
const AttandancecalendraAdmin = () => {
  const [filteredEmployees, setFilteredEmployees] = useState([]);
  const { password, username } = billpunchbasicauth;
  const [showcheckmodal, setshowcheckmodal] = useState(false);
  const [dates, setDates] = useState([]);
  const [departmentData, setDepartmentData] = useState([]);
  const [tableHeaders, setTableHeaders] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const currentYear = new Date().getFullYear();
  const [years, setYears] = useState(currentYear.toString());
  const currentMonth = new Date().getMonth() + 1;
  const [months, setMonths] = useState(currentMonth.toString());
  const [department, setDepartment] = useState("");

  const generateMonthDates = (year, month) => {
    const startDate = new Date(year, month - 1, 1);
    const endDate = new Date(year, month, 0);
    const dates = [];
    let currentDate = startDate;

    while (currentDate <= endDate) {
      dates.push(currentDate);
      currentDate = addDays(currentDate, 1);
    }

    return dates;
  };

  useEffect(() => {
    const year = parseInt(years);
    const month = parseInt(months);
    const generatedDates = generateMonthDates(year, month);
    setDates(generatedDates);
  }, [years, months]);

  const generateMonthHeaders = (year, month) => {
    const dates = generateMonthDates(year, month);

    const date = new Date(years, months - 1, 1);
    const lastDay = new Date(date.getFullYear(), date.getMonth() + 1, 0);
    const datess = dates.length;

    return dates.map((date) => (
      <th key={format(date, "yyyy-MM-dd")}>{format(date, "dd")}</th>
    ));
  };

  useEffect(() => {
    const updatedHeaders = generateMonthHeaders(
      parseInt(years),
      parseInt(months)
    );
    setTableHeaders(updatedHeaders);
  }, [months, years]);

  const [employees, setEmployees] = useState([]);
  const [attendance, setAttendance] = useState({});
  const [attendanceChanges, setAttendanceChanges] = useState([]);

  const handleCheckboxChange = (employeeId, day, isChecked) => {
    const updatedChanges = [...attendanceChanges];
    const updatedEmployees = [...employees];
    const dateStr = format(new Date(day), "yyyy-MM-dd");

    const changeIndex = updatedChanges.findIndex(
      (change) => change.id === employeeId && change.attendance_date === dateStr
    );

    if (isChecked) {
      if (changeIndex === -1) {
        updatedChanges.push({
          id: employeeId,
          firmid: user.firm_id,
          attendance_date: dateStr,
          attendance_status: "Y",
          type: "Attendance",
        });
      } else {
        updatedChanges[changeIndex].attendance_status = "Y";
      }
    } else {
      if (changeIndex === -1) {
        updatedChanges.push({
          id: employeeId,
          firmid: user.firm_id,
          attendance_date: dateStr,
          attendance_status: "N",
          type: "Attendance",
        });
      } else {
        updatedChanges[changeIndex].attendance_status = "N";
      }
    }

    const employeeIndex = updatedEmployees.findIndex(
      (employee) => employee.employee_code === employeeId
    );
    if (employeeIndex !== -1) {
      const dayIndex = updatedEmployees[employeeIndex].data.findIndex(
        (entry) =>
          format(new Date(entry.attendance_date), "yyyy-MM-dd") === dateStr
      );
      if (dayIndex !== -1) {
        updatedEmployees[employeeIndex].data[dayIndex].attendance_status =
          isChecked ? "Y" : "N";
      } else {
        updatedEmployees[employeeIndex].data.push({
          attendance_date: dateStr,
          attendance_status: isChecked ? "Y" : "N",
        });
      }
    }

    setAttendanceChanges(updatedChanges);
    setEmployees(updatedEmployees);
  };

  const handleMarkAttendance = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestBody = JSON.stringify({ requests: attendanceChanges });

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: requestBody,
        redirect: "follow",
      };

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/MarkAttendance`,
        requestOptions
      );

      const data = await response.json();
      if (data.status === "0") {
        getAttandancedata();
        setAttendanceChanges([]);
      } else {
        swal("Error marking attendance:", data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      swal("Error marking attendance:", error);
      setTransactionLoaderState(false);
    }
  };
  const MarkAttendance = async () => {
    if (!validateForm()) {
      swal("Error", "Please fill in all fields.", "error");
      return;
    }

    const { selectedEmployee, selectedDate, inTime, outTime, status } =
      formData;
    const empid = selectedEmployee.value;
    const firmid = selectedEmployee.firmId;

    const dateObj = new Date(selectedDate);
    const attendanceDate = dateObj.toISOString().split("T")[0];

    const requestBody = {
      requests: [
        {
          id: empid,
          firmid,
          attendance_date: attendanceDate,
          attendance_status: status.value,
          type: "Attendance",
          in_time: inTime,
          out_time: outTime,
        },
      ],
    };

    try {
      setTransactionLoaderState(true);
      const headers = new Headers();
      headers.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      headers.append("Content-Type", "application/json");

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/MarkAttendance`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestBody),
        }
      );

      const data = await response.json();
      if (data.status === "0") {
        getAttandancedata();
        setAttendanceChanges([]);
        setFormData({
          selectedEmployee: null,
          selectedDate: null,
          inTime: "",
          outTime: "",
          status: null,
        });
      } else {
        swal("Error marking attendance:", data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      swal("Error marking attendance:", error);
      setTransactionLoaderState(false);
    }
  };
  const getAttandancedata = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        id: user.firm_id,
        month: months,
        year: years,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/GetMarkAttendance`,
        requestOptions
      );

      const data = await res.json();
      if (data.status === "0" || data.status == "1") {
        const updatedAttendance = {};

        const employeesList = data.data;
        const employeesListNew = [];
        const dates = generateMonthDates(years, months);
        const datess = dates.length;
        console.log(datess + "meeeee");

        employeesList.forEach((ee) => {
          const datesNew = [];
          for (let i = 1; i <= datess; i++) {
            let found2 = null;
            ee.data.map((entry) => {
              if (new Date(entry.attendance_date).getDate() == i) {
                found2 = entry;
              }
            });
            if (found2) {
              datesNew.push(found2);
            } else {
              let d2 = new Date();
              d2.setDate(i + 1);
              d2.setMonth(months - 1);
              datesNew.push({
                attendance_date: d2,
                attendance_status: "N",
                month: months,
                year: years,
              });
            }
          }
          //  console.log(datesNew);
          ee.data = datesNew;
          console.log(ee);
          employeesListNew.push(ee);
        });

        console.log(employeesListNew);
        setAttendance(updatedAttendance);
        setEmployees(data.data);
      } else {
        swal("Error fetching attendance data:", data.msg);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const getDepartmentList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({ firmid: user.firm_id });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployeeDepartment`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setDepartmentData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
    }
  };
  const openModal = () => {
    setshowcheckmodal(true);
  };

  const closeModal = () => {
    setshowcheckmodal(false);
  };

  useEffect(() => {
    getDepartmentList();
    getAttandancedata();
  }, [months]);

  useEffect(() => {
    if (employees.length > 0) {
    }
  }, [employees]);
  console.log(employees);

  useEffect(() => {
    if (department !== "") {
      const filtered = employees.filter(
        (employee) => employee.employee_department === department
      );
      setFilteredEmployees(filtered);
    } else {
      setFilteredEmployees(employees);
    }
  }, [department, employees]);

  const { accessData } = useContext(GlobalContext);
  useEffect(() => {
    console.log("Access Data:", accessData);
  }, [accessData]);

  //
  const [empdata, setempdata] = useState([]);
  const [formData, setFormData] = useState({
    selectedEmployee: null,
    selectedDate: null,
    inTime: "",
    outTime: "",
    status: null,
  });
  const getEmployeeList = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        firmId: user.firm_id,
      });

      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
      };
      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/Salary/getEmployee`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setempdata(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    getEmployeeList();
  }, []);
  const handleInputChange = (field, value) => {
    setFormData((prevFormData) => ({
      ...prevFormData,
      [field]: value,
    }));
  };
  const employeeOptions = empdata.map((emp) => ({
    value: emp.empid,
    label: `${emp.firstname} ${emp.lastname}`,
    firmId: emp.firm_id,
  }));

  const statusOptions = [
    { value: "Y", label: "Present" },
    { value: "N", label: "Absent" },
  ];
  const validateForm = () => {
    const { selectedEmployee, selectedDate } = formData;
    return selectedEmployee && selectedDate;
  };
  const handleMarkHalfDay = async () => {
    if (!validateForm()) {
      swal("Error", "Please fill in all fields.", "error");
      return;
    }

    const { selectedEmployee, selectedDate } = formData;
    const empid = selectedEmployee.value;
    const firmid = selectedEmployee.firmId;

    const dateObj = new Date(selectedDate);
    const year = dateObj.getFullYear();
    const month = dateObj.getMonth() + 1;
    const date = dateObj.getDate().toString().padStart(2, "0");
    const formattedDate = `${year}-${month}-${date}`;

    const requestBody = {
      empid,
      date: formattedDate,
      month,
      year,
      firmid,
    };

    try {
      setTransactionLoaderState(true);
      const headers = new Headers();
      headers.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      headers.append("Content-Type", "application/json");

      const response = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/MakHalfDay`,
        {
          method: "POST",
          headers,
          body: JSON.stringify(requestBody),
        }
      );
      const responseData = await response.json();

      if (
        responseData.status === "0" &&
        responseData.result[0].result === "Y"
      ) {
        swal("Success", responseData.result[0].message, "success");
        getAttandancedata();
        setFormData({
          selectedEmployee: null,
          selectedDate: null,
          inTime: "",
          outTime: "",
        });
      } else {
        swal("Error", responseData.msg || "Failed to mark half day", "error");
      }
    } catch (error) {
      console.error("Error marking half day:", error);
      swal("Error", "An error occurred while marking half day.", "error");
    } finally {
      setTransactionLoaderState(false);
    }
  };
  const [showPreview, setShowPreview] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const filteredPreviewEmployees = filteredEmployees.filter((employee) =>
    employee.employee_name.toLowerCase().includes(searchTerm.toLowerCase())
  );
  return (
    <>
      <BulkAttendanceAdmin
        show={showcheckmodal}
        closeModal={closeModal}
        months={months}
      />
      <div className="row" style={{ background: "white" }}>
        <h5 className=" col d-flex align-items-center pt-3 px-3">
          Attendance Management
        </h5>
        <div
          className=" col d-flex justify-content-end align-items-center"
          style={{
            display: "flex",
            flexDirection: "row",
            marginBottom: "10px",
          }}
        >
          <div
            className="color-box mr-2"
            style={{
              backgroundColor: "yellow",
              width: "20px",
              height: "20px",
              borderRadius: "4px",
            }}
          ></div>
          <div
            className="color-name d-flex align-items-center"
            style={{ fontSize: ".8rem" }}
          >
            Sunday
          </div>
        </div>
      </div>
      <Form className="mx-2">
        <Row className="pt-0 pb-0">
          <Col>
            <Form.Group>
              <Form.Label>Select Employee</Form.Label>
              <Select
                options={employeeOptions}
                value={formData.selectedEmployee}
                onChange={(value) =>
                  handleInputChange("selectedEmployee", value)
                }
                placeholder="Select Employee"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Status</Form.Label>
              <Select
                options={statusOptions}
                value={formData.status}
                onChange={(value) => handleInputChange("status", value)}
                placeholder="Select Status"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Select Date</Form.Label>
              <Form.Control
                type="date"
                value={formData.selectedDate || ""}
                onChange={(e) =>
                  handleInputChange("selectedDate", e.target.value)
                }
                placeholder="Select a date"
                className="form-control"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>In Time</Form.Label>
              <Form.Control
                type="time"
                value={formData.inTime}
                onChange={(e) => handleInputChange("inTime", e.target.value)}
                placeholder="In Time"
              />
            </Form.Group>
          </Col>
          <Col>
            <Form.Group>
              <Form.Label>Out Time</Form.Label>
              <Form.Control
                type="time"
                value={formData.outTime}
                onChange={(e) => handleInputChange("outTime", e.target.value)}
                placeholder="Out Time"
              />
            </Form.Group>
          </Col>
        </Row>

        <Row className="pt-0">
          <Col>
            <Button
              variant="primary"
              className="mx-2"
              size="sm"
              onClick={MarkAttendance}
            >
              Mark Attendance
            </Button>
            <Button
              style={{
                background: "#fff",
                color: "#fff",
                border: "1px solid #fff",
              }}
            ></Button>
            <Button variant="secondary" size="sm" onClick={handleMarkHalfDay}>
              Mark Half Day
            </Button>
          </Col>
        </Row>
      </Form>
      <div className="m-2" style={{ background: "white" }}>
        <div className="row ">
          <div className="col-md-9 col-12 p-0 d-flex align-items-center">
            <div className="px-md-2">
              <select
                value={years}
                style={{
                  width: "7rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                onChange={(e) => setYears(e.target.value)}
              >
                <option value="" disabled>
                  Select Year
                </option>{" "}
                <option value="2024">2024</option>
                <option value="2025">2025</option>
                <option value="2026">2026</option>
                <option value="2027">2027</option>
              </select>
            </div>
            <div className="px-md-2">
              <select
                value={months}
                style={{
                  width: "7rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                onChange={(e) => setMonths(e.target.value)}
              >
                <option value={2023}>Month</option>
                <option value={1}>January</option>
                <option value={2}>February</option>
                <option value={3}>March</option>
                <option value={4}>April</option>
                <option value={5}>May</option>
                <option value={6}>June</option>
                <option value={7}>July</option>
                <option value={8}>August</option>
                <option value={9}>September</option>
                <option value={10}>October</option>
                <option value={11}>November</option>
                <option value={12}>December</option>
              </select>
            </div>
            <div className=" px-md-2">
              <select
                style={{
                  width: "9rem",
                  height: "2rem",
                  borderRadius: ".4rem",
                  border: "1px #ced4da solid",
                }}
                value={department}
                onChange={(e) => setDepartment(e.target.value)}
              >
                <option value="">All Department</option>
                {departmentData.map((dept) => (
                  <option key={dept.id} value={dept.cat1}>
                    {dept.cat1}
                  </option>
                ))}
              </select>
            </div>
            <div className=" px-md-2 ">
              {accessData &&
                accessData.map(
                  (module) =>
                    module.data &&
                    module.data.map((role) =>
                      role.role_name === "Attendance Check" ? (
                        role.access === "Y" ? (
                          <span key={role.pk_role_id}>
                            <button
                              style={{
                                height: "2rem",
                                borderRadius: ".4rem",
                                border: "1px #0074D9 solid",
                                fontSize: ".9rem",
                                background: "#fff",
                                color: "#0074D9",
                              }}
                              onClick={openModal}
                            >
                              Check Attendance
                            </button>
                          </span>
                        ) : (
                          <span key={role.pk_role_id}>
                            <button
                              className="btn btn-sm btn-primary d-flex justify-content-center align-items-start"
                              style={{
                                color: "#fff",
                                height: "2rem",
                                border: "none",
                                borderRadius: ".4rem",
                                display: "flex",
                                fontSize: ".9rem",
                                backgroundColor: "gray",
                                cursor: "not-allowed",
                              }}
                              disabled
                            >
                              Check Attendance
                            </button>
                          </span>
                        )
                      ) : null
                    )
                )}
            </div>
            <div className="px-2">
              <Button
                className="btn-sm btn btn-primary"
                onClick={() => setShowPreview(true)}
              >
                Preview
              </Button>
            </div>
          </div>
          <div className="col d-flex justify-content-end   ">
            <div>
              <Button
                className="btn-sm btn btn-success"
                onClick={handleMarkAttendance}
              >
                Save Attendance
              </Button>
            </div>
          </div>
        </div>

        <div style={{ overflowX: "auto", width: "" }}>
          <div style={{ width: "9rem" }}>
            <table className="m-2">
              <thead>
                <tr>
                  <th>Employee </th>

                  {tableHeaders}
                </tr>
              </thead>
              <tbody>
                {filteredEmployees.map((employee) => (
                  <tr key={employee.id}>
                    <td style={{ fontSize: ".8rem" }}>
                      {employee.employee_name}
                    </td>
                    {dates.map((date, i) => (
                      <td
                        key={i}
                        style={{
                          backgroundColor: isSunday(date) ? "#fff8a0" : "white",
                          border: "1px solid #ced4da",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            height: "100%",
                          }}
                        >
                          {accessData &&
                            accessData.map(
                              (module) =>
                                module.data &&
                                module.data.map((role) =>
                                  role.role_name === "Add Attendance" &&
                                  role.access === "Y" ? (
                                    <span
                                      key={role.pk_role_id}
                                      style={{ marginLeft: "10px" }}
                                    >
                                      <input
                                        type="checkbox"
                                        style={{
                                          margin: 0,
                                          display: "inline-block",
                                        }}
                                        checked={employee.data.some(
                                          (entry) =>
                                            format(
                                              new Date(entry.attendance_date),
                                              "yyyy-MM-dd"
                                            ) === format(date, "yyyy-MM-dd") &&
                                            entry.attendance_status === "Y"
                                        )}
                                        onChange={(e) => {
                                          handleCheckboxChange(
                                            employee.employee_code,
                                            date,
                                            e.target.checked
                                          );
                                        }}
                                      />
                                    </span>
                                  ) : null
                                )
                            )}
                          {employee.data.some(
                            (entry) =>
                              format(
                                new Date(entry.attendance_date),
                                "yyyy-MM-dd"
                              ) === format(date, "yyyy-MM-dd") &&
                              entry.type === "WFH"
                          ) && (
                            <span style={{ marginLeft: "5px", color: "green" }}>
                              <FaHome />
                            </span>
                          )}
                          {employee.data.some(
                            (entry) =>
                              format(
                                new Date(entry.attendance_date),
                                "yyyy-MM-dd"
                              ) === format(date, "yyyy-MM-dd") &&
                              entry.type === "SL"
                          ) && (
                            <span
                              style={{
                                marginLeft: "5px",
                                color: "red",
                                fontSize: ".8rem",
                              }}
                            >
                              <b> SL</b>
                            </span>
                          )}
                          {employee.data.some(
                            (entry) =>
                              format(
                                new Date(entry.attendance_date),
                                "yyyy-MM-dd"
                              ) === format(date, "yyyy-MM-dd") &&
                              entry.type === "PL"
                          ) && (
                            <span
                              style={{
                                marginLeft: "5px",
                                color: "blue",
                                fontSize: ".8rem",
                              }}
                            >
                              <b> PL</b>
                            </span>
                          )}
                          {employee.data.some(
                            (entry) =>
                              format(
                                new Date(entry.attendance_date),
                                "yyyy-MM-dd"
                              ) === format(date, "yyyy-MM-dd") &&
                              entry.type === "HalfDay"
                          ) && (
                            <span
                              style={{ marginLeft: "10px", color: "green" }}
                            >
                              <img
                                src={require("../../../assets/images/half-time.svg")}
                                style={{
                                  width: "auto",
                                  height: "4vh",
                                }}
                              />
                            </span>
                          )}
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        </div>
      </div>
      {showPreview && (
        <Modal
          show={showPreview}
          onHide={() => setShowPreview(false)}
          size="lg"
        >
          <Modal.Header closeButton>
            <Modal.Title>Attendance Preview</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {/* Search Input */}
            <Form.Group className="mb-2">
              <Form.Control
                type="text"
                placeholder="Search Employee..."
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
                style={{ width: "20vw" }}
              />
            </Form.Group>

            <div
              style={{
                maxHeight: "70vh",
                overflowY: "auto",
                overflowX: "auto", scrollbarWidth:"none", msOverflowStyle:"none"
              }}
            >
              <table className="table table-bordered">
                <thead
                  style={{
                    position: "sticky",
                    top: 0,
                    background: "white",
                    zIndex: 2,
                  }}
                >
                  <tr>
                    <th>Employee</th>
                    <th>Total Present</th>
                    <th>Total Absent</th>
                    <th>SL Balance</th>
                    <th>PL Balance</th>
                    <th>Total used PL</th>
                    <th>Total used SL</th>
                    <th>Total WFH</th>
                    <th>Total HalfDay</th>
                    {tableHeaders}
                  </tr>
                </thead>
                <tbody>
                  {filteredPreviewEmployees.map((employee) => {
                    const totalPresent = employee.data.filter(
                      (entry) => entry.attendance_status === "Y"
                    ).length;
                    const totalAbsent = dates.length - totalPresent;

                    const totalPL = employee.data.filter(
                      (entry) => entry.type === "PL"
                    ).length;

                    const totalSL = employee.data.filter(
                      (entry) => entry.type === "SL"
                    ).length;

                    const totalWFH = employee.data.filter(
                      (entry) => entry.type === "WFH"
                    ).length;

                    const totalHalfday = employee.data.filter(
                      (entry) => entry.type === "HalfDay"
                    ).length;
                    return (
                      <tr key={employee.id}>
                        <td>{employee.employee_name}</td>
                        <td style={{ fontWeight: "bold", color: "green" }}>
                          {totalPresent}
                        </td>
                        <td style={{ fontWeight: "bold", color: "red" }}>
                          {totalAbsent}
                        </td>
                        <td style={{ fontWeight: "bold", color: "green" }}>
                          {employee.sl_bal}
                        </td>
                        <td style={{ fontWeight: "bold", color: "green" }}>
                          {employee.pl_bal}
                        </td>
                        <td style={{ fontWeight: "bold", color: "blue" }}>
                          {totalPL}
                        </td>
                        <td style={{ fontWeight: "bold", color: "orange" }}>
                          {totalSL}
                        </td>
                        <td style={{ fontWeight: "bold", color: "purple" }}>
                          {totalWFH}
                        </td>
                        <td style={{ fontWeight: "bold", color: "purple" }}>
                          {totalHalfday}
                        </td>
                        {dates.map((date, i) => (
                          <td key={i} style={{ textAlign: "center" }}>
                            {employee.data.some(
                              (entry) =>
                                format(
                                  new Date(entry.attendance_date),
                                  "yyyy-MM-dd"
                                ) === format(date, "yyyy-MM-dd") &&
                                entry.attendance_status === "Y"
                            )
                              ? "✔️"
                              : "❌"}
                          </td>
                        ))}
                      </tr>
                    );
                  })}
                </tbody>
              </table>
            </div>
          </Modal.Body>
        </Modal>
      )}
    </>
  );
};
export default AttandancecalendraAdmin;
