import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useMediaQuery } from "@react-hook/media-query";
import { IoIosCall } from "react-icons/io";
import "bootstrap/dist/css/bootstrap.min.css";

const Main = () => {
  const isSmallScreen = useMediaQuery("(max-width: 825px)");

  const [leftSectionStyle, setLeftSectionStyle] = useState({
    transform: isSmallScreen ? "none" : "translateX(-100%)",
    opacity: isSmallScreen ? 1 : 0,
    transition: isSmallScreen ? "none" : "transform 1s ease-out, opacity 1s ease-out",
  });

  const [rightSectionStyle, setRightSectionStyle] = useState({
    transform: isSmallScreen ? "none" : "translateX(100%)",
    opacity: isSmallScreen ? 1 : 0,
    transition: isSmallScreen ? "none" : "transform 1s ease-out, opacity 1s ease-out",
  });

  const handleHover = (event) => {
    event.target.style.background = "#fff";
    event.target.style.color = "#0085ff";
  };

  const handleHoverExit = (event) => {
    event.target.style.background = "#0085ff";
    event.target.style.color = "#fff";
  };

  useEffect(() => {
    if (!isSmallScreen) {
      setLeftSectionStyle((prevState) => ({
        ...prevState,
        transform: "translateX(0)",
        opacity: 1,
      }));

      setRightSectionStyle((prevState) => ({
        ...prevState,
        transform: "translateX(0)",
        opacity: 1,
      }));
    }
  }, [isSmallScreen]);

  return (
    <>
     

      <div
        className="row pb-0 main"
        style={{ background: "radial-gradient(circle, #ddefff, #ffffff)" }}
      >
        <div className="col-md-1"></div>
        <div
          className="col-md-5 col-12 mx-md-0 mx-3 d-flex align-items-center"
          style={leftSectionStyle}
        >
          <div>
            <h1
              style={{
                fontWeight: "700",
              }}
            >
              Unlock Your <br />
              Team's Potential with
            </h1>
            <h2
              className="m-0 p-0"
              style={{
                color: "#0085ff",
                fontWeight: "700",
              }}
            >
              BillPunch!
            </h2>
            <h6 className="m-0 p-0">
              A complete ecosystem for your employee lifecycle
            </h6>
            <div className="row mt-3">
              <div className="col px-md-2 border-right">
                <h6>200+</h6>
                <h6>Customer</h6>
              </div>

              <div className="col border-right">
                <h6>1000+</h6>
                <h6>User</h6>
              </div>
              <div className="col">
                <h6>40+</h6>
                <h6>Module</h6>
              </div>
            </div>
            <div className="px-md-1 mt-2">
              <a href="tel:9958854324">
                <Button
                  style={{
                    borderRadius: "0",
                    display: "flex",
                    alignItems: "center",
                  }}
                  onMouseOver={handleHover}
                  onMouseOut={handleHoverExit}
                >
                  <IoIosCall style={{ marginRight: "8px" }} />
                  <h6 className="p-0 m-0">Schedule a call Now</h6>
                </Button>
              </a>
            </div>
          </div>
        </div>
        <div
          className="col-md-5 col-12 solutiondiv mt-3 d-flex justify-content-end"
          style={rightSectionStyle}
        >
          <img
            className="mainpic"
            alt="Billpunch"
            src={require("../../../assets/images/mainbancom.png")}
          />
        </div>
        <div className="col-1"></div>
      </div>
    </>
  );
};

export default Main;
