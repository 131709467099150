import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const Attendance = () => {
  const [pageData, setPageData] = useState(null);

  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "10");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };
  useEffect(() => {
    fetchSeoData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.src = "./on_seo.js";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar />
      <h1 className="text-center mt-5 px-5">
        Best Attendance Management System in India 2025 – Accurate <br/>Time Tracking
        & Workforce Automation{" "}
      </h1>
      <div className="d-flex justify-content-center">
        <img src="https://billpunch.com/billpunchImages/One%20HR%20Tool%20.png" style={{height:"50vh"}}/>
        </div>
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
       
          <h2>Seamless, Automated, and Real-Time Attendance Management</h2>
          <p>
            In today's fast-paced work environment, tracking employee attendance
            accurately is essential for maintaining workplace productivity and
            operational efficiency. BillPunch’s HRMS Attendance Management
            System offers an advanced, automated attendance tracking solution
            that integrates seamlessly with biometric devices, mobile apps, and
            cloud-based platforms. Whether your workforce is remote, hybrid, or
            office-based, our system ensures real-time insights, accuracy, and
            compliance with India's labor laws. As one of the best attendance
            management systems in India, BillPunch provides a smart and flexible
            solution tailored for businesses of all sizes.
          </p>
          <h6>Key Features of BillPunch's Attendance Management System</h6>
          <h5>Automated Time Tracking & Real-Time Monitoring</h5>
          <ul>
            <li>
              Seamless integration with biometric devices, RFID swipe cards, and
              mobile apps.
            </li>
            <li>
              Real-time syncing of attendance data for accuracy and fraud
              prevention.
            </li>
            <li>Eliminates manual errors, buddy punching, and time theft.</li>
          </ul>

          <h5>Customizable Attendance Policies & Shift Management</h5>
          <ul>
            <li>
              Supports rotational shifts, night shifts, and flexible work hours.
            </li>
            <li>
              Automatic calculation of late arrivals, early departures, and
              overtime.
            </li>
            <li>
              Custom rules for Indian companies across states with varied labor
              laws.
            </li>
          </ul>

          <h5>Mobile Attendance & Geolocation Tracking</h5>
          <ul>
            <li>
              GPS-enabled mobile clock-in for remote workers and field
              employees.
            </li>
            <li>
              Geo-fencing to restrict attendance marking outside designated work
              areas.
            </li>
            <li>
              Instant alerts for attendance irregularities and discrepancies.
            </li>
          </ul>

          <h5>Seamless Integration with Leave & Payroll Management</h5>
          <ul>
            <li>
              Auto-sync attendance data with leave tracking and payroll
              processing.
            </li>
            <li>
              Direct integration with India’s best payroll systems for accurate
              salary calculations.
            </li>
            <li>
              Eliminates payroll errors due to missing attendance data or
              unapproved leaves.
            </li>
          </ul>

          <h5>Advanced Reporting, Analytics & Compliance</h5>
          <ul>
            <li>
              Real-time dashboards displaying attendance trends and workforce
              insights.
            </li>
            <li>Customizable attendance reports for HR and payroll teams.</li>
            <li>
              Ensures compliance with Indian labor laws (Shops & Establishments
              Act, Factories Act).
            </li>
          </ul>

          <h5>
            Why Choose BillPunch – Best Attendance Management Software in India?
          </h5>
          <ul>
            <li>
              <strong>Increased Productivity:</strong> Accurate tracking of
              working hours ensures fair payroll processing. Reduces HR workload
              by automating attendance and leave tracking.
            </li>
            <li>
              <strong>Enhanced Accuracy & Transparency:</strong> Eliminates
              manual entry errors and prevents fake attendance marking. Builds
              trust between employees and management with clear attendance
              policies.
            </li>
            <li>
              <strong>Cost Savings & Compliance:</strong> Avoids payroll
              discrepancies and unnecessary salary expenses. Adheres to
              state-wise labor regulations across India.
            </li>
            <li>
              <strong>Better Workforce Insights:</strong> Data-driven decisions
              on absenteeism, workforce planning, and overtime management. Helps
              HR teams optimize staffing levels based on real-time analytics.
            </li>
          </ul>

          <h5>Transform Your Attendance Management with BillPunch</h5>
          <p>
            BillPunch’s HRMS Attendance Management Module is designed to
            simplify employee attendance tracking, enhance workplace efficiency,
            and ensure payroll accuracy. With automated time tracking,
            geolocation features, and payroll integration, organizations can
            eliminate attendance-related errors and focus on business growth.
          </p>

          <p>
            Join India’s leading businesses in adopting the best attendance
            management software in 2025.
          </p>
        </div>
        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Attendance;
