import React, { useContext, useEffect, useState } from "react";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import Select from "react-select";
import { GlobalContext } from "../../Context/GlobalState";
import { billpunchbasicauth } from "../../utils/Credentials";
import { Button, Row, Tab, Tabs } from "react-bootstrap";
const Employeequestion = ({ Employeetechid, Employeeid }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [activeTab, setActiveTab] = useState("technical");

  return (
    <>
      <Tabs
        id="appraisal-tabs"
        activeKey={activeTab}
        onSelect={(tab) => setActiveTab(tab)}
        className="mb-3"
      >
        <Tab eventKey="technical" title="Technical">
          <Techdetail Employeetechid={Employeetechid} Employeeid={Employeeid} />
        </Tab>

        <Tab eventKey="mcq" title="MCQ">
          <Mcq Employeetechid={Employeetechid} Employeeid={Employeeid} />
        </Tab>

        <Tab eventKey="firmReview" title="Firm Review">
          <Firmdetail Employeetechid={Employeetechid} Employeeid={Employeeid} />
        </Tab>

        <Tab eventKey="selfReview" title="Self Review">
        <Selfreview Employeetechid={Employeetechid} Employeeid={Employeeid} />
        </Tab>
      </Tabs>
    </>
  );
};
const Firmdetail = ({ Employeeid }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const GetAppraisalCompany = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({}),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAppraisalCompany`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setQuestions(data.data);
        setFilteredQuestions(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetAllQuestionAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: Employeeid,
          type: "Firm",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllQuestionAnswer`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setAnswers(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    if (searchTerm) {
      setFilteredQuestions(
        questions.filter((q) =>
          q.question_text.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredQuestions(questions);
    }
  }, [searchTerm, questions]);
  const formatAnswer = (answer) => {
    if (!answer) return null;
    const points = answer.split(/\d\)/).filter((point) => point.trim() !== "");
    return (
      <ul>
        {points.map((point, index) => (
          <li key={index}>{point.trim()}</li>
        ))}
      </ul>
    );
  };
  useEffect(() => {
    GetAppraisalCompany();
    GetAllQuestionAnswer();
  }, []);
  return (
    <>
      {/* <input
            type="text"
            placeholder="Search by question..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            style={{ marginBottom: "10px", padding: "5px", width: "300px" }}
          /> */}
      <div>
        {filteredQuestions.map((question) => {
          const answer = answers.find(
            (ans) => ans.question_id === question.question_id
          );
          return (
            <div key={question.question_id} style={{ marginBottom: "15px" }}>
              <p className="mb-2" style={{ fontWeight: "bold" }}>
                {question.question_text}
              </p>
              <p>
                <strong>Answer:</strong>
                {answer
                  ? formatAnswer(answer.selected_ans)
                  : "No answer provided"}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};
const Techdetail = ({ Employeetechid, Employeeid }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [questions, setQuestions] = useState([]);
  const [answers, setAnswers] = useState([]);
  const [filteredQuestions, setFilteredQuestions] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");

  const GetAppraisalCompany = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          techid: Employeetechid,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAppraisalQuestion`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setQuestions(data.data);
        setFilteredQuestions(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };
  const GetAllQuestionAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: Employeeid,
          type: "Tech1",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllQuestionAnswer`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0") {
        setAnswers(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    if (searchTerm) {
      setFilteredQuestions(
        questions.filter((q) =>
          q.question_text.toLowerCase().includes(searchTerm.toLowerCase())
        )
      );
    } else {
      setFilteredQuestions(questions);
    }
  }, [searchTerm, questions]);
  const formatAnswer = (answer) => {
    if (!answer) return null;
    const points = answer.split(/\d\)/).filter((point) => point.trim() !== "");
    return (
      <ul>
        {points.map((point, index) => (
          <li key={index}>{point.trim()}</li>
        ))}
      </ul>
    );
  };
  useEffect(() => {
    GetAppraisalCompany();
    GetAllQuestionAnswer();
  }, []);
  return (
    <>
      {/* <input
          type="text"
          placeholder="Search by question..."
          value={searchTerm}
          onChange={(e) => setSearchTerm(e.target.value)}
          style={{ marginBottom: "10px", padding: "5px", width: "300px" }}
        /> */}
      <div>
        {filteredQuestions.map((question) => {
          const answer = answers.find(
            (ans) => ans.question_id === question.question_id
          );
          return (
            <div key={question.question_id} style={{ marginBottom: "15px" }}>
              <p className="mb-2" style={{ fontWeight: "bold" }}>
                {question.question_text}
              </p>
              <p>
                <strong>Answer:</strong>
                {answer
                  ? formatAnswer(answer.selected_ans)
                  : "No answer provided"}
              </p>
            </div>
          );
        })}
      </div>
    </>
  );
};
const Mcq = ({ Employeetechid, Employeeid }) => {
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { password, username } = billpunchbasicauth;
  const [mcqData, setMcqData] = useState([]);
  const [financialYear, setFinancialYear] = useState(null);
  const [quarterlyYear, setQuarterlyYear] = useState(null);
  const [showInputs, setShowInputs] = useState(false);
  const decodeText = (text) => {
    return decodeURIComponent(text.replace(/â€™/g, "’"));
  };
  const generateFinancialYearOptions = () => {
    const startYear = 2024;
    const years = [];
    for (let i = startYear; i < startYear + 5; i++) {
      years.push({
        value: `${i}-${i + 1}`,
        label: `${i}-${i + 1}`,
      });
    }
    return years;
  };
  const generateQuarterlyYearOptions = () => {
    const quarters = [
      { value: "1", label: "Q1 (April - June)" },
      { value: "2", label: "Q2 (July - September)" },
      { value: "3", label: "Q3 (October - December)" },
      { value: "4", label: "Q4 (January - March)" },
    ];
    return quarters;
  };
  const GetAppraisalMCQ = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          techid: Employeetechid,
          quarter: quarterlyYear.value,
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAppraisalMCQ`,
        requestOptions
      );
      const data = await res.json();
      if (data.status === "0" && data.data) {
        setMcqData(data.data);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ questions:", error);
      setTransactionLoaderState(false);
    }
  };
  const financialYearOptions = generateFinancialYearOptions();
  const quarterlyYearOptions = generateQuarterlyYearOptions();
  const [selectedAnswers, setSelectedAnswers] = useState({});

  const handleOptionChange = (questionId, selectedOption) => {
    setSelectedAnswers((prev) => ({
      ...prev,
      [questionId]: selectedOption,
    }));
  };
  const handleButtonClick = () => {
    if (financialYear && quarterlyYear) {
      setShowInputs(true);
      GetAppraisalMCQ();
      GetAllQuestionAnswer();
    }
  };
  const GetAllQuestionAnswer = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append(
        "Authorization",
        "Basic " + Buffer.from(username + ":" + password).toString("base64")
      );
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          empid: Employeeid,
          type: "Tech",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllQuestionAnswer`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0" && data.data) {
        const answers = {};
        data.data.forEach((item) => {
          answers[item.question_id] = item.selected_ans;
        });
        setSelectedAnswers(answers);
      }
      setTransactionLoaderState(false);
    } catch (error) {
      console.error("Error fetching MCQ answers:", error);
      setTransactionLoaderState(false);
    }
  };

  return (
    <div className="m-3">
      <h5 className="mb-0">Technical Evaluation</h5>
      <Row>
        <div className="col-3 pr-0">
          <Select
            options={financialYearOptions}
            value={financialYear}
            onChange={setFinancialYear}
            placeholder="Select Financial Year"
          />
        </div>
        <div className="col-3">
          <Select
            options={quarterlyYearOptions}
            value={quarterlyYear}
            onChange={setQuarterlyYear}
            placeholder="Select Quarterly Year"
          />
        </div>

        <div className="col d-flex" style={{ justifyContent: "space-between" }}>
          <Button size="sm" variant="primary" onClick={handleButtonClick}>
            Search
          </Button>
        </div>
      </Row>

      {showInputs && (
        <div className="">
          <div>
            {mcqData.length > 0 ? (
              mcqData.map((question, index) =>
                index % 2 === 0 ? (
                  <div className="row pb-0 pt-0" key={question.id}>
                    {/* First Question */}
                    <div className="col-md-6 p-2">
                      <label
                        className="form-label"
                        style={{ fontSize: ".9rem" }}
                      >
                        {decodeText(question.question)}
                      </label>
                      <div>
                        {["A", "B", "C", "D"].map((option, i) => (
                          <div key={i} style={{ fontSize: ".9rem" }}>
                            <input
                              type="radio"
                              name={`q${question.id}`}
                              value={option}
                              onChange={() =>
                                handleOptionChange(question.id, option)
                              }
                              checked={selectedAnswers[question.id] === option}
                            />{" "}
                            {question[`option_${option.toLowerCase()}`]}
                          </div>
                        ))}
                      </div>
                    </div>

                    {mcqData[index + 1] && (
                      <div className="col-md-6 p-2">
                        <label
                          className="form-label"
                          style={{ fontSize: ".9rem" }}
                        >
                          {decodeText(mcqData[index + 1].question)}
                        </label>
                        <div>
                          {["A", "B", "C", "D"].map((option, i) => (
                            <div key={i} style={{ fontSize: ".9rem" }}>
                              <input
                                type="radio"
                                name={`q${mcqData[index + 1].id}`}
                                value={option}
                                onChange={() =>
                                  handleOptionChange(
                                    mcqData[index + 1].id,
                                    option
                                  )
                                }
                                checked={
                                  selectedAnswers[mcqData[index + 1].id] ===
                                  option
                                }
                              />{" "}
                              {
                                mcqData[index + 1][
                                  `option_${option.toLowerCase()}`
                                ]
                              }
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                ) : null
              )
            ) : (
              <p>No MCQs available.</p>
            )}
          </div>
        </div>
      )}
    </div>
  );
};
const Selfreview = ({  Employeeid }) => {
    const { user, setTransactionLoaderState } = useContext(GlobalContext);
    const { password, username } = billpunchbasicauth;
    const [mcqData, setMcqData] = useState([]);
    const [selectedAnswers, setSelectedAnswers] = useState({});
    const decodeText = (text) => {
      return decodeURIComponent(text.replace(/â€™/g, "’"));
    };
    const GetAppraisalMCQSelf = async () => {
      setTransactionLoaderState(true);
      try {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");
  
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({}),
          redirect: "follow",
        };
  
        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAppraisalMCQSelf`,
          requestOptions
        );
        const data = await res.json();
        if (data.status === "0" && data.data) {
          setMcqData(data.data);
        }
        setTransactionLoaderState(false);
      } catch (error) {
        console.error("Error fetching MCQ questions:", error);
        setTransactionLoaderState(false);
      }
    };
    const handleOptionChange = (questionId, value) => {
      setSelectedAnswers((prev) => ({
        ...prev,
        [questionId]: value,
      }));
    };
    const GetAllQuestionAnswer = async () => {
      setTransactionLoaderState(true);
      try {
        const myHeaders = new Headers();
        myHeaders.append(
          "Authorization",
          "Basic " + Buffer.from(username + ":" + password).toString("base64")
        );
        myHeaders.append("Content-Type", "application/json");
  
        const requestOptions = {
          method: "POST",
          headers: myHeaders,
          body: JSON.stringify({
            empid: Employeeid,
            type: "Self",
          }),
          redirect: "follow",
        };
  
        const res = await fetch(
          `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetAllQuestionAnswer`,
          requestOptions
        );
        const data = await res.json();
  
        if (data.status === "0" && data.data) {
          const answers = {};
          data.data.forEach((item) => {
            answers[item.question_id] = item.selected_ans;
          });
          setSelectedAnswers(answers);
        }
  
        setTransactionLoaderState(false);
      } catch (error) {
        console.error("Error fetching MCQ answers:", error);
        setTransactionLoaderState(false);
      }
    };
    useEffect(() => {
      GetAppraisalMCQSelf();
      GetAllQuestionAnswer();
    }, []);
  
    return (
      <div className="m-3">
        <h5 className="mb-0">Self Evaluation</h5>
        <div className="">
          {mcqData.length > 0 ? (
            mcqData.map((question, index) =>
              index % 2 === 0 ? (
                <div className="row pb-0 pt-0" key={question.question_id}>
                  {/* First Question */}
                  <div className="col-md-6 p-2">
                    <label className="form-label" style={{ fontSize: ".9rem" }}>
                      {decodeText(question.question_text)}
                    </label>
                    <div>
                      {["a", "b", "c", "d"].map((option, i) => (
                        <div key={i} style={{ fontSize: ".9rem" }}>
                          <input
                            type="radio"
                            name={`q${question.question_id}`}
                            value={option}
                            onChange={() =>
                              handleOptionChange(question.question_id, option)
                            }
                            checked={
                              selectedAnswers[question.question_id] === option
                            }
                          />{" "}
                          {
                            question[
                              `option_${
                                ["poor", "good", "very_good", "excellent"][i]
                              }`
                            ]
                          }
                        </div>
                      ))}
                    </div>
                  </div>
  
                  {/* Second Question (if exists) */}
                  {mcqData[index + 1] && (
                    <div className="col-md-6 p-2">
                      <label className="form-label" style={{ fontSize: ".9rem" }}>
                        {decodeText(mcqData[index + 1].question_text)}
                      </label>
                      <div>
                        {["a", "b", "c", "d"].map((option, i) => (
                          <div key={i} style={{ fontSize: ".9rem" }}>
                            <input
                              type="radio"
                              name={`q${mcqData[index + 1].question_id}`}
                              value={option}
                              onChange={() =>
                                handleOptionChange(
                                  mcqData[index + 1].question_id,
                                  option
                                )
                              }
                              checked={
                                selectedAnswers[
                                  mcqData[index + 1].question_id
                                ] === option
                              }
                            />{" "}
                            {
                              mcqData[index + 1][
                                `option_${
                                  ["poor", "good", "very_good", "excellent"][i]
                                }`
                              ]
                            }
                          </div>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              ) : null
            )
          ) : (
            <p>No MCQs available.</p>
          )}
        </div>
     
      </div>
    );
  };
export default Employeequestion;
