import React, { useEffect, useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { Helmet } from "react-helmet";
import Navbar from "./Navbar";
import Footer from "./footer";

const Expensemanagement = () => {
  const [pageData, setPageData] = useState(null);

  const fetchSeoData = async () => {
    try {
      const response = await fetch("/seo.json");
      const data = await response.json();

      if (data.status === "0" && Array.isArray(data.data)) {
        const pages = data.data[0]?.pages || [];

        console.log("Pages Data:", pages);

        const faqPageData = pages.find((page) => page.page_id === "12");

        if (faqPageData) {
          console.log("FAQ Page Data:", faqPageData.basic_settings.tittle);
          setPageData(faqPageData);
        } else {
          console.log("FAQ page not found");
        }
      }
    } catch (error) {
      console.error("Error fetching SEO data:", error);
    }
  };

  useEffect(() => {
    fetchSeoData();
    window.scrollTo({ top: 0, behavior: "smooth" });
    const script = document.createElement("script");
    script.src = "./on_seo.js";
    script.type = "module";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);

  return (
    <>
      <Navbar />
      <h1 className="text-center mt-4 px-5 mx-5">
      Best Expense Management Software in India 2025 – Organize & Track Expense Efficiently
      </h1>
      <div className="d-flex justify-content-center">
         
         <img src="https://billpunch.com/billpunchImages/HR%20Crisis.png" style={{height:"50vh"}}/></div>
      <div className="row mt-md-2">
        <div className="col-md-1"></div>
        <div className="col-md-8">
  <h2>Simplify & Automate Your Corporate Expense Management</h2>
  <p>
    Managing business expenses efficiently is key to maintaining financial health, compliance, and employee satisfaction. 
    BillPunch’s HRMS Expense Management System offers a powerful, automated expense tracking solution designed to ensure transparency, 
    reduce manual work, and streamline the reimbursement process. Whether your company deals with:
  </p>
  <ul>
    <li>Travel expenses</li>
    <li>Office purchases</li>
    <li>Project-related costs</li>
  </ul>
  <p>Our cloud-based expense management software helps businesses control spending, automate approvals, and integrate with payroll systems.</p>

  <h5>Key Features of BillPunch's Expense Management Software</h5>

  <h5>Easy-to-Use Interface with Mobile Access</h5>
  <ul>
    <li>Intuitive dashboard for quick expense submission and approval tracking.</li>
    <li>Mobile-friendly app for on-the-go expense reporting and receipt uploads.</li>
  </ul>

  <h5>Hassle-Free Expense Submission & Categorization</h5>
  <ul>
    <li>Supports multi-format receipt uploads (PDF, images, scanned bills).</li>
    <li>Categorize expenses (travel, food, office supplies, fuel, lodging) for better tracking.</li>
  </ul>

  <h5>Automated Approval Workflows & Real-Time Notifications</h5>
  <ul>
    <li>Customizable multi-level approval system based on amount, department, or category.</li>
    <li>Instant alerts for managers to approve or reject claims without delays.</li>
  </ul>

  <h5>Policy Compliance & Fraud Prevention</h5>
  <ul>
    <li>Automated policy checks to enforce company expense guidelines.</li>
    <li>Real-time violation alerts for out-of-policy claims.</li>
  </ul>

  <h5>Advanced Expense Tracking, Reports & Analytics</h5>
  <ul>
    <li>Detailed reports on spending trends, department-wise expenses, and top spending categories.</li>
    <li>AI-powered analytics to identify cost-saving opportunities.</li>
  </ul>

  <h5>Seamless Integration with Payroll, ERP & Banking Systems</h5>
  <ul>
    <li>ERP integration for automatic data syncing and financial reconciliation.</li>
    <li>Direct banking integration for faster and secure reimbursements.</li>
  </ul>

  <h5>Data Security, Compliance & Audit Readiness</h5>
  <ul>
    <li>Encrypted data storage to protect sensitive financial information.</li>
    <li>Audit trails to track and verify all expense-related activities.</li>
  </ul>

  <h5>Dedicated Employee Support & Training Resources</h5>
  <ul>
    <li>Integrated helpdesk for queries related to expense claims and approvals.</li>
    <li>Step-by-step tutorials to help employees navigate the system with ease.</li>
  </ul>

  <h5>Why Choose BillPunch – Best Expense Management Software in India?</h5>

  <h5>Boosts Efficiency</h5>
  <ul>
    <li>Automates expense tracking, approval, and reimbursements, saving time and effort.</li>
  </ul>

  <h5>Ensures Transparency</h5>
  <ul>
    <li>Complete visibility into expenses and reimbursements to build trust.</li>
  </ul>

  <h5>Enhances Cost Control & Compliance</h5>
  <ul>
    <li>Real-time expense monitoring prevents overspending.</li>
    <li>Strict policy enforcement ensures financial discipline.</li>
  </ul>

  <h5>Increases Employee Satisfaction</h5>
  <ul>
    <li>Quick reimbursements improve employee experience and morale.</li>
  </ul>

  <h5>Transform Your Business with BillPunch's Expense Management System</h5>
  <p>
    BillPunch’s HRMS Expense Management Module is a powerful tool for businesses in India looking to streamline financial operations, 
    automate approvals, and maintain compliance. From automated receipt scanning to bank-integrated reimbursements, we help organizations 
    reduce costs and improve efficiency.
  </p>
</div>

        <div className="col-md-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Expensemanagement;
