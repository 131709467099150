import React, { useState, useEffect, useContext } from "react";
import { Badge } from "react-bootstrap";
import { Link } from "react-router-dom";
import Nabvar from "./Navbar";
import Footer from "./footer";
import MyLanding from "./Cards";
import { GlobalContext } from "../../Context/GlobalState";
import { BASE_URL_API_TRANXT_UAT_API, BASE_URL_API_TRANXT_UAT_API_GOSEO } from "../../utils/Constants";
import { FaUserTie } from "react-icons/fa";
const Blog = () => {
  const tags = [
    "Business",
    "Corporate",
    "Finance",
    "Invest",
    "Planning",
    "Sport",
    "News",
  ];
  const [postdata, setpostdata] = useState([]);
  const { user, setTransactionLoaderState } = useContext(GlobalContext);

  const Getproductblogs = async () => {
    setTransactionLoaderState(true);
    try {
      const myHeaders = new Headers();
      myHeaders.append("Content-Type", "application/json");

      const requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: JSON.stringify({
          product_name: "billpunch",
        }),
        redirect: "follow",
      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/GetProductBlogs`,
        requestOptions
      );
      const data = await res.json();

      if (data.status === "0") {
        setpostdata(data.data || []);
      } else {
        console.error("Failed to fetch blogs:", data.msg);
      }
    } catch (error) {
      console.log("error", error);
    } finally {
      setTransactionLoaderState(false);
    }
  };
  useEffect(() => {
    Getproductblogs();
    const script = document.createElement("script");
    script.innerHTML = `
      document.addEventListener("DOMContentLoaded", function() {
          console.log("Running SEO script...");

          const seoUrl = "https://seobix.com/integrations/meta_seo.php";
           const requestBody = JSON.stringify({
              weburl: "https://billpunch.com",
              fileurl: "https://billpunch.com/SeoSettings_638749552913980001.enc",
              type:"Seo"
          });

          fetch(seoUrl, {
              method: "POST",
              headers: {
                  "Content-Type": "application/json"
              },
              body: requestBody
          })
          .then(response => response.json())
          .then(seoData => {
              console.log("SEO Data:", seoData);

              if (seoData.status !== "1" || !seoData.data || seoData.data.length === 0) {
                  console.error("Invalid SEO response format");
                  return;
              }

             const currentUrl = window.location.href.replace(/\/$/, '').replace(/^https?:\/\/www\./, 'https://');
            console.log(currentUrl);
            const page = seoData.data[0].pages.find(p => p.page_url === currentUrl);
            if (!page) {
                console.error(currentUrl+"Page not found");
                return;
            }

              const { basic_settings, og_settings, keyword_settings } = page;

              if (basic_settings.tittle) document.title = basic_settings.tittle;

              const metaTags = {
                  "description": basic_settings.meta_desc,
                  "keywords": keyword_settings.map(k => k.keyword).join(", "),
                  "og:title": og_settings.og_title,
                  "og:description": og_settings.og_desc,
                  "og:image": og_settings.og_image,
                  "og:url": og_settings.og_url,
                  "og:type": og_settings.og_type,
                  "og:site_name": og_settings.og_sitename,
                  "twitter:title": og_settings.twitter_title,
                  "twitter:description": og_settings.twitter_desc,
                  "twitter:image": og_settings.twitter_image
              };

              Object.entries(metaTags).forEach(([key, value]) => {
                  if (value) {
                      let metaTag = document.querySelector(\`meta[name="\${key}"], meta[property="\${key}"]\`);
                      if (!metaTag) {
                          metaTag = document.createElement("meta");
                          if (key.startsWith("og:") || key.startsWith("twitter:")) {
                              metaTag.setAttribute("property", key);
                          } else {
                              metaTag.setAttribute("name", key);
                          }
                          document.head.appendChild(metaTag);
                      }
                      metaTag.setAttribute("content", value);
                  }
              });
          })
          .catch(error => console.error("SEO Script Error:", error));
      });
    `;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script);
    };
  }, []);
  return (
    <>
      <Nabvar />
      <div className="row">
        <div className="col-1"></div>
        <div className="col-10">
          <div className="row " style={{ overflow: "hidden", height: "auto" }}>
            <div
              className="col-md-9 col-12 m-0 p-0 "
              style={{ marginRight: "2rem" }}
            >
              <div className="" style={{ marginRight: "2rem" }}>
                <div className="row d-flex justify-content-between align-items-end px-2  pt-2 pb-md-2 pb-1"></div>
                <MyLanding />
              </div>
            </div>

            <div className="col-md-3 col-12 p-md-0 p-5 ">
              <div className="d-flex flex-column justify-content-start align-items-center mt-5 ">
                <h6
                  style={{
                    fontFamily: "Roboto, sans-serif",
                    fontWeight: "500",
                  }}
                >
                  Related Tags 
                </h6>
                <div
                  className="d-flex flex-wrap"
                  style={{ textAlign: "start", justifyContent: "center" }}
                >
                  {tags.map((tag, index) => (
                    <Badge
                      key={index}
                      className="btn btn-outline-secondary mx-2 mt-2"
                      style={{ borderRadius: ".4rem" }}
                    >
                      {tag}
                    </Badge>
                  ))}
                </div>
              </div>

              <div className="d-flex flex-column justify-content-start align-items-center mt-4">
                {postdata.map((blog) => (
                  <div
                    key={blog.post_id}
                    className="d-flex align-items-center mb-3"
                    style={{ textAlign: "start" }}
                  >
                    <img
                      src={blog.post_featured_image}
                      alt="Author"
                      style={{
                        width: "60px",
                        height: "60px",
                        borderRadius: "50%",
                        marginRight: "1rem",
                      }}
                    />
                    <Link
                      to={`/blog/${blog.post_slug}`}
                      onClick={() => {
                        console.log("click hua");
                        sessionStorage.setItem("Slug", JSON.stringify(blog));
                      }}
                      style={{ textDecoration: "none" }}
                    >
                      <div className="ms-3">
                        <p
                          style={{
                            fontSize: ".8rem",
                            margin: 0,
                            color: "black",
                          }}
                        >
                          {blog.post_title}
                        </p>
                        <p
                          style={{
                            fontSize: ".8rem",
                            color: "#686D76",
                            margin: 0,
                            fontWeight: "600",
                          }}
                        >
                          
                          <FaUserTie
                            style={{ fontSize: ".9rem", color: "grey" }}
                          />
                          &nbsp; {blog.post_author}
                        </p>
                      </div>
                    </Link>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
        <div className="col-1"></div>
      </div>

      <Footer />
    </>
  );
};

export default Blog;
