import React, { useContext, useEffect, useState } from "react";
import {
  Link,
  Redirect,
  useHistory,
} from "react-router-dom/cjs/react-router-dom.min";
import "bootstrap/dist/css/bootstrap.min.css";
import { BASE_URL_API_TRANXT_UAT_API } from "../../utils/Constants";
import { GlobalContext } from "../../Context/GlobalState";
import cogoToast from "cogo-toast";
import swal from "sweetalert";
import Congratulations from "../../../assets/images/congrats.gif";
const SmallScreenNavbar = ({ login, toggleNavbar }) => {
  return (
    <div className="small-screen-navbar">
      <button
        className="close-button-smallnav"
        onClick={toggleNavbar}
        aria-label="Close Navigation"
      >
        <i className="fas fa-times"></i>
      </button>

      <ul>
        <li>
          <a href="/" onClick={toggleNavbar}>
            Home
          </a>
        </li>
        <li>
          <a href="/Pricing" onClick={toggleNavbar}>
            Pricing
          </a>
        </li>
        <li>
          <a href="/About_us">AboutUs </a>
        </li>
        <li>
          <a href="/Blogs">Blogs</a>
        </li>

        <li>
          <Link onClick={login}>Login </Link>
        </li>
      </ul>
    </div>
  );
};

const Navbar = () => {
  const [isSticky, setIsSticky] = useState(false);
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 908);
  const [isNavbarOpen, setIsNavbarOpen] = useState(false);
  const history = useHistory();
  const { user, setTransactionLoaderState } = useContext(GlobalContext);
  const { setUserInfo } = useContext(GlobalContext);
  const { setUserParent } = useContext(GlobalContext);

  const scrollToTop = () => {
    window.scrollTo({ top: 0, behavior: "auto" });
  };

  const handleScroll = () => {
    setIsSticky(window.scrollY > 0);
  };

  const handleResize = () => {
    setIsSmallScreen(window.innerWidth <= 908);
  };

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const refundVerification = (userData) => {
    swal({
      title: "",
      text: "",
      content: {
        element: "div",
        attributes: {
          innerHTML: `
            <img src="${Congratulations}" alt="Congratulations" style="width: 150px; height: auto; display: block; margin: 0 auto;" />
            <h2>Congratulations!</h2>
            <p>You have successfully logged in. Proceed to registration to use Billpunch for managing your firm.</p>
          `,
        },
      },
      buttons: {
        cancel: {
          text: "Cancel",
          className: "btn-sm btn-danger",
          value: false,
          visible: true,
        },
        proceed: {
          text: "Proceed",
          value: true,
          className: "btn-success btn-sm",
        },
      },
      dangerMode: false,
    }).then((willProceed) => {
      if (willProceed) {
        history.push({
          pathname: "/New_login",
          state: userData,
        });
      }
    });
  };

  const queryParameters = new URLSearchParams(window.location.search);
  const tokenid1 = queryParameters.get("accessToken");
  const Getssologin = async () => {
    setTransactionLoaderState(true);
    try {
      var myHeaders = new Headers();

      myHeaders.append("Content-Type", "application/json");

      var raw = JSON.stringify({
        token: tokenid1,
        product: "BillPunch",
      });
      console.log(raw);
      var requestOptions = {
        method: "POST",
        headers: myHeaders,
        body: raw,
        redirect: "follow",
        mode: "cors", 

      };

      const res = await fetch(
        `${BASE_URL_API_TRANXT_UAT_API}/api/SuperAdmin/BillPunchSSOLoginV1`,
        requestOptions
      );
      const data = await res.json();
      if (data.responseCode === "00") {
        cogoToast.success(data.responseMessage);
        setUserInfo(data.response);
        const userData = data.profiledetails.data[0];
        console.log("parentAccountId:", userData);
        setUserParent(userData);
       
        setTransactionLoaderState(false);
      } else if (data.responseCode === "01") {
        login();
      } else if (data.responseCode === "02") {
        const userData = data.profiledetails.data[0];
        refundVerification(userData);
      }

      setTransactionLoaderState(false);
    } catch (error) {
      console.log("error", error);
      setTransactionLoaderState(false);
    }
  };

  useEffect(() => {
    if (tokenid1) {
      Getssologin();
    }
  }, [tokenid1]);

  const login = () => {
    const callbackUrl = `${window.location.origin}`;
    const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}&product_id=${2}`;
    window.location.href = redirectUrl;
  };
  // const login = () => {
  //   const callbackUrl = `${window.location.origin}/Identity`;
  //   const redirectUrl = `https://accounts.bumppy.com/?callback=${callbackUrl}&product_id=${2}`;
  //   window.location.href = redirectUrl;
  // };
  return (
    <>
      <GlobalContext.Consumer>
        {(state) =>
          state.user == null || state.user.us_type == "" ? (
            <div>
              <link
                rel="stylesheet"
                href="https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css"
              />
              {isSmallScreen ? (
                <div>
                  <button className="toggle-button" onClick={toggleNavbar}>
                    <i className="fas fa-bars"></i>
                  </button>
                  {isNavbarOpen && (
                    <SmallScreenNavbar
                      login={login}
                      toggleNavbar={toggleNavbar}
                    />
                  )}
                </div>
              ) : (
                <nav className={`navsalary1 px-0 ${isSticky ? "sticky" : ""}`}>
                  <div className="row m-0 p-0 px-3 navsalary1">
                    <div className="col-1"></div>
                    <div className="col p-md-0 logo">
                      <Link className="m-0" onClick={scrollToTop} to="/">
                        <img
                          className="billpunchlogo m-0"
                          style={{ height: "2rem" }}
                          src={require("../../../assets/images/logoblack.png")}
                          alt="Billpunch"
                        />
                      </Link>
                    </div>
                    <input type="checkbox" id="click" />
                    <ul className="col m-0 p-0 align-items-center d-flex justify-content-end">
                      <li>
                        <a href="/" onClick={scrollToTop}>
                          Home
                        </a>
                      </li>
                      <li>
                        <a href="/Pricing">Pricing </a>
                      </li>

                      <li>
                        <a href="/About_us">AboutUs </a>
                      </li>
                      <li>
                      <a href="/Blogs">Blogs </a>
                      </li>
                      <li>
                        <Link onClick={login}>Login </Link>
                      </li>
                    </ul>
                    <div className="col-md-1 m-0 p-0"></div>
                  </div>
                </nav>
              )}
            </div>
          ) : state.user.us_type == "A" ? (
            <Redirect to="/retailer/AdminDashboard" />
          ) : state.user.us_type == "S" ? (
            <Redirect to="/retailer/Superadmin_dashboard" />
          ) : state.user.us_type == "H" ? (
            <Redirect to="/retailer/HRDashboard" />
          ) : state.user.us_type == "E" ? (
            <Redirect to="/retailer/EmployeeDashboard" />
          ) : (
            <Redirect to="/" />
          )
        }
      </GlobalContext.Consumer>
    </>
  );
};

export default Navbar;
